import React, { useEffect, useRef, useState } from 'react';
import { useColorModeValue, Flex, Box, Text, Progress, List, ListItem, Button, Stack, Spacer, Textarea, Icon, Input, IconButton, Wrap, WrapItem, useToast, Avatar, useMediaQuery, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import { Media, Video } from '@vidstack/player-react';
import poster from '../../../../assets/img/e-learn.jpg';

import { FaArrowCircleLeft, FaArrowLeft, FaCheckCircle, FaPaperPlane } from 'react-icons/fa';

import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';

import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import { StarIcon } from "@chakra-ui/icons";
import Moment from 'react-moment'
import _ from 'lodash';



import { useStateContext } from '../../../../context/ContextProvider';
import Lottie from 'react-lottie';
import feed from '../../../../assets/lot/feeds.json';
import rating from '../../../../assets/lot/rating.json';


const defaultOpt = {
    loop: true,
    autoplay: true,
    animationData: feed,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: rating,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export const Submodcontent = React.memo(({ idmed, idmod, i, showButton }) => {
    const [selectedSection, setSelectedSection] = useState(null);

    const [isTerminated, setTerminated] = useState(false);

    const [videos, setVideos] = useState([])
    const [imagex, setImagex] = useState()

    const videoRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);

    const location = useLocation()

    const [pmc, setProgModContent] = useState([]);
    const [progres, setProgres] = useState([]);
    const [apprenants, setApprenants] = useState([]);
    const [media, setMedia] = useState([]);

    const [progressId, setProgressId] = useState()
    const [progvid, setProgressVideo] = useState()


    const [feedback, setFeedBack] = useState([])

    const { user, setUser } = useStateContext();

    const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");


    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])



    const progress = 100 / location.state.chapcount



    useEffect(() => {
        axiosClient.get(`modvideo/?module_id=${location.state.idmodule}`)
            .then((res) => {
                setVideos(res.data)
            })
    }, [])



    // console.log("dhdhdhdhdhdd", videos);


    const { shuffle } = _; // destructure the shuffle method from lodash

    // useEffect(() => {
    //     axiosClient.get('feedback/').then((res) => {
    //         // Shuffle the list of feedbacks
    //         const shuffledFeedbacks = shuffle(res.data);

    //         // Select the first 3 feedbacks from the shuffled list
    //         const randomFeedbacks = shuffledFeedbacks.slice(0, 4);

    //         setFeedBack(randomFeedbacks);
    //     });
    // }, []);

    useEffect(() => {
        const fetchAllProgressModContent = async () => {
            let allPmc = [];

            // setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogressmod/', { params: { apprenant: user.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                // console.error("Error fetching progress mod content:", error);
            }

            // Set the progress mod content and end loading
            setProgModContent(allPmc);
            // setLoading(false); // End loading after fetching
        };

        fetchAllProgressModContent();
    }, [user.id]); // Add user.id as a dependency

    useEffect(() => {
        const fp = async () => {
            let allPmc = [];

            try {
                const res = await axiosClient.get('cprogress/', { params: { apprenant: user.id, module: location.state.idmodule } });
                const data = res.data;

                // Convert data to an array if it's not already
                allPmc = Array.isArray(data) ? data : [data]; // Wrap in an array if it's not

            } catch (error) {
                // console.error("Error fetching progress content:", error);
            }

            // Set the progress mod content
            setProgres(allPmc);
        };

        fp();
    }, [user.id]);
    // useEffect(() => {
    //     axiosClient.get('media/')
    //         .then((res) => {
    //             setMedia(res.data)
    //         })
    // }, [])

    const [message, setMessage] = useState('')
    const [id_Module] = useState(location.state.idm)
    const [image, setImage] = useState(null);

    const [idApprenant] = useState(1)
    let show = []

    const toast = useToast()
    const positions = [
        'top',
        'top-right',
        'top-left',
        'bottom',
        'bottom-right',
        'bottom-left',
    ]



    useEffect(() => {
        axiosClient.get(`progres/geProgress/?idModule=${location.state.idmodule}&idApprennant=${user.id}`)
            .then((res) => {
                setProgressId(res.data)
            })
    }, [])

    const sendFeedback = () => {
        const formData = new FormData()
        formData.append('id_module', location.state.idmodule)
        formData.append('message', message)
        formData.append('apprenant', user.id)

        axiosClient.post('/feedback/', formData)

        toast({
            title: ` Envoyé avec succes !`,
            position: "bottom-right",
            isClosable: true,
        })
    }


    const handleProgress = (event) => {
        const video = event.target;
        // Use destructuring:
        const { currentTime, duration } = video;
        setProgressVideo((currentTime / duration) * 100);
        setCurrentTime(currentTime);

    };


    const handleSectionClick = (section) => {
        setSelectedSection(section);
        const video = document.querySelector('video');
        video.currentTime = section.timestamp;
    };


    // useEffect(() => {
    //     const video = document.querySelector('video');

    //     const handleBeforeUnload = (event) => {
    //         if (video.currentTime > 0 && !video.paused && !video.ended) {
    //             event.preventDefault();
    //             event.returnValue = '';
    //         }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    if (
        videos.id === location.state.idm
    ) {
    }


    function count() {
        const mediaCount = [];
        media.forEach((m) => {
            if (m.chapitre === location.state.idchapitre) {

                const index = mediaCount.findIndex((item) => item.id === media.id);
                if (index >= 0) {
                    mediaCount[index].count++;
                } else {
                    mediaCount.push({ id: m.id, count: 1 });
                }


            }
        });

        return mediaCount.length
    }

    const mediaCountLength = count();

    const navigate = useNavigate()

    const [completedMedia, setCompletedMedia] = useState(new Set());

    // const checkPassed = (idMedia, idApp) => {
    //     const handleButtonClick = (val) => {
    //         // Update the local state immediately
    //         setCompletedMedia(prev => new Set(prev).add(idMedia));

    //         axiosClient
    //             .put(`progressmodcontent/${val.id}/`, {
    //                 etat: true,
    //                 media: idMedia,
    //                 apprenant: user.id,
    //             })
    //             .then(() => {
    //                 const promises = [];

    //                 // Handle progress updates here
    //                 const formData = new FormData();

    //                 // Debugging: Log progress value
    //                 // console.log("Progress before appending:", progress);
    //                 // if (isNaN(progress)) {
    //                 //     console.error("Progress is NaN");
    //                 //     return; // Prevent further execution
    //                 // }

    //                 // if (isNaN(progress)) {
    //                 //     console.error("Progress is NaN, setting to 0");
    //                 //     progress = 0; // or set to an appropriate default value
    //                 // }



    //                 formData.append('progres', progress);
    //                 formData.append('module', location.state.idmodule);
    //                 formData.append('apprenant', user.id);
    //                 promises.push(
    //                     axiosClient.post('progres/', formData)
    //                         .catch((error) => {
    //                             if (error.response.status === 400) {
    //                                 // Handle the 400 Bad Request error silently
    //                                 console.log(''); // or do nothing
    //                             } else {
    //                                 // Log or handle other errors as desired
    //                                 // console.error();
    //                             }
    //                         })
    //                 );
    //                 // Toast notification
    //                 toast({
    //                     title: 'Terminé',
    //                     description: "Video completé avec succes !",
    //                     status: 'info',
    //                     position: 'top-right',
    //                     duration: 3000,
    //                     isClosable: true,
    //                 })
    //                 // Update existing progress if needed
    //                 progres.forEach((prog) => {
    //                     if (prog.module === location.state.idmodule && prog.apprenant === user.id) {
    //                         prog.progres += progress;
    //                         const progressFormData = new FormData();
    //                         progressFormData.append('progres', prog.progres);
    //                         promises.push(axiosClient.patch(`/progres/${prog.id}/`, progressFormData));
    //                     }
    //                 });

    //                 // Wait for all promises to complete
    //                 Promise.all(promises)
    //                     .then((responses) => {
    //                         // console.log(responses);
    //                     })
    //                     .catch((error) => {
    //                         // console.error("Error updating progress:", error);
    //                     });
    //             })
    //             .catch((error) => {
    //                 // console.error("Error updating media progress:", error);
    //             });
    //     };

    //     return pmc.map((val) => {
    //         if (val.apprenant === idApp && val.media === idMedia) {
    //             // Check if the media is completed
    //             const isCompleted = completedMedia.has(idMedia) || val.etat;
    //             // console.log("isCompleted", idMedia);


    //             if (isCompleted) {
    //                 return (
    //                     <Button key={val.id} isDisabled bg={"green"}>
    //                         <Flex alignItems="center">
    //                             <Icon color={"white"} as={FaCheckCircle} mr="2" />
    //                             <Text color={"white"}>Terminé</Text>
    //                         </Flex>
    //                     </Button>
    //                 );
    //             } else {
    //                 return (
    //                     <Button key={val.id} bg={"#FFD24C"} onClick={() => handleButtonClick(val)}>
    //                         Marquer comme terminé
    //                     </Button>
    //                 );
    //             }
    //         }
    //         return null;
    //     });

    // };



    const checkPassed = (idMedia, idApp) => {
        const handleButtonClick = (val) => {
            // Update the local state immediately
            setCompletedMedia(prev => new Set(prev).add(idMedia));

            axiosClient
                .put(`progressmodcontent/${val.id}/`, {
                    etat: true,
                    media: idMedia,
                    apprenant: user.id,
                })
                .then(() => {
                    const promises = [];
                    const formData = new FormData();

                    // Prepare data for the request
                    formData.append('progres', progress);
                    formData.append('module', location.state.idmodule);
                    formData.append('apprenant', user.id);

                    // // Check if progress exists for the user and module
                    // if (progres && progres.apprenant === user.id && progres.module === location.state.idmodule) {
                    //     // Update existing progress
                    //     progres.progress += progress; // Update the progress value
                    //     const progressFormData = new FormData();
                    //     progressFormData.append('progres', progres.progress);
                    //     promises.push(axiosClient.patch(`/progres/${progres.id}/`, progressFormData)
                    //         .catch(() => {
                    //             // Suppress error logging
                    //         }));
                    // } else {
                    //     // Only make the POST request if there's no existing progress
                    //     promises.push(
                    //         axiosClient.post('progres/', formData)
                    //             .catch(() => {
                    //                 // Suppress error logging
                    //             })
                    //     );
                    // }

                    // Wait for all promises to complete
                    Promise.all(promises)
                        .then(() => {
                            // Handle successful responses if needed
                        })
                        .catch(() => {
                            // Suppress error logging
                        });
                })
                .catch(() => {
                    // Suppress error logging
                });
        };

        return pmc.map((val) => {
            if (val.apprenant === idApp && val.media === idMedia) {
                const isCompleted = completedMedia.has(idMedia) || val.etat;

                if (isCompleted) {
                    return (
                        <Button key={val.id} isDisabled bg={"green"}>
                            <Flex alignItems="center">
                                <Icon color={"white"} as={FaCheckCircle} mr="2" />
                                <Text color={"white"}>Terminé</Text>
                            </Flex>
                        </Button>
                    );
                } else {
                    return (
                        <Button key={val.id} bg={"#FFD24C"} onClick={() => handleButtonClick(val)}>
                            Marquer comme terminé
                        </Button>
                    );
                }
            }
            return null;
        });
    };
    function ChatMessage({ message, time, avt, sender, like }) {
        return (
            <Flex justify={"flex-start"} mb="3">

                <Box position="relative">
                    <Avatar size="sm" src={avt} position="absolute" top="0" left="0" />
                    <Text fontSize={"12px"} color={useColorModeValue("gray.400", "gray.500")} fontStyle={'oblique'} ml={"45px"}>{sender}</Text>
                    <Box
                        bg={useColorModeValue("gray.200", "gray.500")}
                        color={useColorModeValue("gray.700", "white.100")}
                        px="3"
                        py="2"
                        rounded="3xl"
                        shadow="md"
                        w={'100%'}
                        ml="8" // Add margin to push message to the right of the avatar
                    >
                        <Flex justify="space-between" direction="column">
                            <Box textAlign="right">
                                <Text fontSize="2xs" fontWeight="bold" color="gray.400">
                                    <Moment format='MMMM DD YYYY , h:mm:ss a'>
                                        {time}

                                    </Moment>

                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize="sm" textAlign="left">
                                    {message}
                                </Text>
                            </Box>
                            <Box textAlign="right">
                                <Flex justify='end'>
                                    {like &&
                                        <AiFillHeart color={like && 'red'} fontSize="20" cursor='pointer' defaultValue={like} />
                                    }
                                    {!like &&
                                        <AiOutlineHeart fontSize="20" cursor='pointer' defaultValue={like} />
                                    }

                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        );
    }


    const [listRatingwithIds, setListRatingwithIds] = useState([]);


    useEffect(() => {

        axiosClient
            .get(
                `/rating/getRatingsByIds/?idModule=${location.state.idmodule}&idApprenant=${user.id}`
            )
            .then((res) => setListRatingwithIds(res.data));

    }, []);


    const [raiting, setRaiting] = useState('');



    const getRating = () => {
        let a

        listRatingwithIds.map((val, key) => {
            a = val.rating
        })
        return a
    }
    const rt = getRating()

    const [isOpen, setIsOpen] = useState(false);


    const OverlayOne = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='4px'
        />
    )
    const [overlay, setOverlay] = React.useState(<OverlayOne />)



    const Rating = ({ defaultValue = 0, maxStars = 5, messages = [] }) => {
        const [rating, setRating] = useState(defaultValue);
        const [hovered, setHovered] = useState(-1);


        const handleStarClick = (index) => {
            setRating(index + 1);
            const hasUserRated = listRatingwithIds.some((rating) => rating.apprenant === user.id);

            if (messages[index]) {
                toast({
                    title: "Merci infiniment pour votre feedback ! Vos commentaires sont très précieux pour nous aider à améliorer notre service.",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right"
                });

                if (!hasUserRated) {
                    axiosClient.post("rating/", {
                        "apprenant": user.id,
                        "module": location.state.idmodule,
                        "rating": index + 1,
                    }).then(() => {
                        setListRatingwithIds([...listRatingwithIds, { apprenant: user.id }]);
                    });
                    setIsOpen(false)
                } else {
                    listRatingwithIds.map((val, key) => {
                        const formData = new FormData();
                        formData.append("rating", index + 1);
                        axiosClient.patch(`/rating/${val.id}/`, formData);
                    })

                    setIsOpen(false)
                }


            }
        };


        const handleStarHover = (index) => {
            setHovered(index);
        };

        const renderStars = () => {
            const stars = [];

            for (let i = 0; i < maxStars; i++) {
                stars.push(
                    <Box
                        key={i}
                        onClick={() => handleStarClick(i)}
                        onMouseEnter={() => handleStarHover(i)}
                        onMouseLeave={() => handleStarHover(-1)}
                    >
                        <StarIcon
                            color={i <= (hovered - 1) || i <= (rating - 1) ? "yellow.400" : "gray.200"}
                            boxSize={9}
                            p={1}
                        />
                    </Box>
                );
            }

            return <Flex direction="row">{stars}</Flex>;
        };

        return <Box>{renderStars()}</Box>;
    };


    const handleRefresh = () => {
        axiosClient.get('feedback/').then((res) => {
            const shuffledFeedbacks = shuffle(res.data);
            const randomFeedbacks = shuffledFeedbacks.slice(0, 6);
            setFeedBack(randomFeedbacks);
        });
    };


    // const [avatarUrl, setAvatarUrl] = useState([]);

    // useEffect(() => {
    //     axiosClient.get(`/apprenants/${user.id}/image`)
    //         .then(response => {
    //             setAvatarUrl(response.request.responseURL);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }, [user.id]);







    function getProgressOfModule() {
        let p
        progres.map((prog, k) => {
            if (prog.module === location.state.idmodule)
                p = prog.progres
        })
        return p
    }


    const ppx = getProgressOfModule()
    // console.log("progvidGGGG", progvid);


    const inp_ = useColorModeValue('white', 'gray.800')

    // console.log("iiiiiiiii", i);

    const [isPlaying, setIsPlaying] = useState(false);
    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        // Here you can add logic to mark the video as completed
        toast({
            title: 'Video Completed',
            description: "Video completed successfully!",
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };
    const { isOpen: isExitModalOpen, onOpen: openExitModal, onClose: closeExitModal } = useDisclosure();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isPlaying) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isPlaying]);

    useEffect(() => {
        const handlePopState = (event) => {
            if (isPlaying) {
                event.preventDefault();
                setIsLeavingPage(true);
                openExitModal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isPlaying, openExitModal]);



    const [isLeavingPage, setIsLeavingPage] = useState(false);

    const handleLeaveConfirm = () => {
        closeExitModal();
        if (isLeavingPage) {
            navigate(-1);
        }
    };

    const handleLeaveCancel = () => {
        closeExitModal();
        if (isLeavingPage) {
            window.history.pushState(null, '', window.location.pathname);
            setIsLeavingPage(false);
        }
    };
    return (


        <>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {overlay}
                <ModalContent style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}>
                    <ModalBody rounded='md'  >
                        <Lottie width={'75%'} options={defaultOption} />

                    </ModalBody>
                    <ModalBody h={'55%'}>
                        <Text color={'blue.600'} fontSize={'xl'} align={"center"} fontWeight={"bold"} fontFamily={"revert-layer"}>
                            Votre avis nous intéresse !
                        </Text>
                        <Text fontWeight={'medium'} fontFamily={"cursive"}>
                            Si vous avez un moment, pourriez-vous nous donner une note en utilisant les étoiles ci-dessous ? Votre feedback nous aide à améliorer notre service pour vous et pour les autres clients. Merci d'avance pour votre contribution.

                        </Text>
                        <Flex justify={'center'} mt={2}>
                            <Rating defaultValue={3} maxStars={5} messages={[1, "2", "3", "4", "5"]} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => setIsOpen(false)}>
                            Plus tard
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

            <Modal isOpen={isExitModalOpen} onClose={handleLeaveCancel}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Quitter la vidéo ?</ModalHeader>
                    <ModalBody>
                        Vous êtes en train de regarder une vidéo. Êtes-vous sûr de vouloir quitter ? Votre progression pourrait être perdue.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleLeaveConfirm}>
                            Quitter
                        </Button>
                        <Button variant="ghost" onClick={handleLeaveCancel}>Annuler</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Flex justifyContent={'flex-end'}>

                {/* 
                <Button bg={"#089bd7"} px={{ base: '4', md: '6' }} width={"10%"} onClick={() => navigate((-1))}>
                    <Flex alignItems="center">
                        <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                        <Text color={'#ffd140'}>Retour</Text>
                    </Flex>
                </Button> */}

            </Flex>
            <Flex mt="3" w="full" alignItems="center" justifyContent="center">
                <Box
                    width={{ base: "100%", sm: "80%", md: "70%", lg: "100%" }}
                    boxShadow="lg"
                    borderRadius="lg"
                    height="700px"
                    overflowY="auto"
                    css={{
                        "&::-webkit-scrollbar": {
                            width: "11px",
                            height: "6px",

                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#10316B",
                            borderRadius: "6px",

                        },
                        "&::-webkit-scrollbar-track": {
                            background: "gray.50",
                        },
                    }}
                >
                    {/* || val.id === idmed */}
                    <Flex justifyContent="center">
                        {videos.map((val, key) => {
                            if (val.id === i) {
                                return (
                                    <div key={`media-${val.id}`}
                                    >
                                        <Media key={`media-${val.id}`}>
                                            <Video
                                                loading="visible"
                                                poster={poster}
                                                key={`media-${val.id}`}
                                                controls
                                                preload="true"
                                                onTimeUpdate={handleProgress}
                                                onPlay={handlePlay}
                                                onPause={handlePause}
                                                onEnded={handleEnded}
                                            >
                                                <video

                                                    loading="visible"
                                                    poster={poster}
                                                    src={val.file}
                                                    preload="none"
                                                    data-video="0"
                                                    controls
                                                    ref={videoRef}
                                                    controlsList="nodownload" // Disable download


                                                />


                                            </Video>


                                        </Media>
                                        {/* <div>
                                            <iframe src="
                                            
                                            https://drive.google.com/uc?id=1wFHWJMsOJ4jxd0GYK530y1pTwlydPn_O
                                            "></iframe>
                                        </div>                */}
                                        {/* <MediaPlayer
                                            src={val.file}
                                            poster={poster}
                                            controls
                                            onTimeUpdate={handleProgress}
                                            ref={videoRef}


                                        >
                                           
                                            <MediaOutlet />

                                        </MediaPlayer> */}

                                    </div>
                                )
                            }
                        })}

                    </Flex>


                    <Box bg={useColorModeValue('white', 'gray.700')} p="4" >
                        {videos.map((val) => {
                            if (val.id === location.state.idm) {
                                return (
                                    <Text key={val.id} px="4" fontWeight="bold">
                                        {val.name}
                                    </Text>
                                );
                            }
                            return null; // Return null if the condition is not met
                        })}
                        <Box px="4">
                            <Progress value={progvid} size='sm' bg={useColorModeValue('yellow.400', 'yellow.200')} />

                        </Box>
                        <Text px="4" fontSize="sm" fontWeight="bold">
                            {formatTime(currentTime)}
                        </Text>

                        {videos.map((val, key) => {
                            if (val.id === i) {
                                let show = [];
                                if (pmc.length > 0) {
                                    show = pmc.filter(
                                        (resultat) => resultat.apprenant === user.id && resultat.media === val.id
                                    );
                                }

                                return (
                                    <React.Fragment key={val.id}> {/* Adding a unique key here */}
                                        {progvid === 100 || (pmc.length > 0 && show[0]?.etat) ? (
                                            <Flex justifyContent="end" mr="4">
                                                {checkPassed(val.id, user.id, progress)}
                                            </Flex>
                                        ) : null}
                                    </React.Fragment>
                                );
                            }
                            return null; // Ensure a return value if conditions are not met
                        })}

                        {/* <List mt="4">
                            <ListItem
                                fontWeight={selectedSection === 1 ? 'bold' : 'normal'}
                                onClick={() => handleSectionClick({ timestamp: 10, section: 'Introduction' })}
                                cursor="pointer"
                            >
                                React structure
                            </ListItem>
                            <ListItem
                                fontWeight={selectedSection === 2 ? 'bold' : 'normal'}
                                onClick={() => handleSectionClick({ timestamp: 45, section: 'Main Topic 1' })}
                                cursor="pointer"
                            >
                                React props
                            </ListItem>
                            <ListItem
                                fontWeight={selectedSection === 3 ? 'bold' : 'normal'}
                                onClick={() => handleSectionClick({ timestamp: 100, section: 'Main Topic 2' })}
                                cursor="pointer"
                            >
                                React js / React native
                            </ListItem>
                        </List> */}
                        <Box mt="4" p="4" >
                            {/* 
                            <Button onClick={() => verify()}>verify</Button>
                            <Text>{progress === 100 ? "terminated" : "non terminated"}</Text> */}
                            {/* <Icon as={FaCheckCircle} color={progress === 100 ? "green" : "grey"} h="32px" w="32px" mr={{ base: "20px", md: "60px" }} /> */}




                            {/* 
                            <Flex ml={1} mt={5}>
                                <Rating defaultValue={getRating} maxStars={5} messages={rate} />

                              
                            </Flex> */}

                            {/* <Flex justify="space-between" alignItems="center">

                                <Box mt={7} textAlign="left" w="40%">
                                    <Button bg={'transparent'} leftIcon={<RepeatIcon fontSize={25} />} onClick={handleRefresh}></Button>

                                    {feedback.map((feed, index) => {
                                        return apprenants.map((app, k) => {
                                            if (feed.id_module === location.state.idmodule && feed.apprenant === app.id) {

                                                let dt = new Date(feed.created_at).toLocaleTimeString('fr-FR')
                                                let dtd = new Date(feed.created_at).toLocaleDateString('fr-FR')

                                                return < ChatMessage  key={feed.id} message={feed.message} time={feed.created_at} avt={app.image} sender={app.first_name + " " + app.last_name} like={feed.like} />;
                                            }
                                        })
                                    })}
                                </Box>
                                <Box
                                    display={isNotSmallerScreen ? "block" : "none"}
                                    w="50%" h="auto">

                                    <Lottie height={'100%'} width={'100%'} options={defaultOpt} />
                                </Box>
                            </Flex> */}
                        </Box>
                    </Box>
                    {/* 
                    {

                        progvid === 100 ? toast({
                            title: 'Terminé',
                            description: "Video completé avec succes !",
                            status: 'info',
                            position: 'top-right',
                            duration: 5000,
                            isClosable: true,
                        }) : null

                    } */}

                </Box>
            </Flex>

        </>

    );
})