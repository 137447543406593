import { Box, Button, Flex, IconButton, Text, useToast, Stack, Icon } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import Embed from 'react-embed';

import { Document, Page, pdfjs } from 'react-pdf';
import file from '../../../../assets/file.pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaArrowCircleLeft, FaCheckCircle } from 'react-icons/fa';
import { useStateContext } from '../../../../context/ContextProvider';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import Lottie from 'react-lottie';
import animationData from '../../../../assets/lot/pdfLoad.json'; // Replace with your actual path




export default function SContenuPpt({ idmed, idmod, showButton, goBack }) {

    const toast = useToast()

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const location = useLocation()
    const [docs, setDocs] = useState([]);
    const [media, setMedia] = useState([]);

    const [pmc, setProgModContent] = useState([]);
    const [progres, setProgres] = useState([]);

    const [progressId, setProgressId] = useState()

    const { user, setUser } = useStateContext();
    const progress = 100 / location.state.chapcount

    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)

            })
    }, [])
    const [idA, setIdA] = useState(null)

    useEffect(() => {
        if (user && user.id) {
            setIdA(user.id)
        }
    }, [user])

    // useEffect(() => {
    //     axiosClient.get('docs/')
    //         .then((res) => {
    //             setDocs(res.data)
    //         })
    // }, [])




    // useEffect(() => {
    //     axiosClient.get('media/')
    //         .then((res) => {
    //             setMedia(res.data)
    //         })
    // }, [])
    useEffect(() => {
        const fetchAllProgressModContent = async () => {
            let allPmc = [];

            // setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogressmod/', { params: { apprenant: user.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                // console.error("Error fetching progress mod content:", error);
            }

            // Set the progress mod content and end loading
            setProgModContent(allPmc);
            // setLoading(false); // End loading after fetching
        };

        fetchAllProgressModContent();
    }, [user.id]); // Add user.id as a dependency


    useEffect(() => {
        const fp = async () => {
            let allPmc = [];

            // setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogress/', { params: { apprenant: user.id, module: location.state.idmodule } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                // console.error("Error fetching progress  content:", error);
            }

            // Set the progress mod content and end loading
            setProgres(allPmc);
            // setLoading(false); // End loading after fetching
        };

        fp();
    }, [user.id, location.state.idmodule]);


    useEffect(() => {
        axiosClient.get(`progres/geProgress/?idModule=${location.state.idmodule}&idApprennant=${user.id}`)
            .then((res) => {
                setProgressId(res.data);
            })
            .catch((error) => {
                // console.error("Error fetching progress:", error);
            });
    }, [location.state.idmodule, user.id]);












    // console.log("pmcvvvvvvv", pmc);


    const [completedMedia, setCompletedMedia] = useState(new Set());

    // const checkPassed = (idMedia, idApp) => {
    //     const handleButtonClick = (val) => {
    //         // Update the local state immediately
    //         setCompletedMedia(prev => new Set(prev).add(idMedia));

    //         axiosClient
    //             .put(`progressmodcontent/${val.id}/`, {
    //                 etat: true,
    //                 media: idMedia,
    //                 apprenant: user.id,
    //             })
    //             .then(() => {
    //                 const promises = [];

    //                 // Handle progress updates here
    //                 const formData = new FormData();

    //                 // Debugging: Log progress value
    //                 // console.log("Progress before appending:", progress);
    //                 // if (isNaN(progress)) {
    //                 //     console.error("Progress is NaN");
    //                 //     return; // Prevent further execution
    //                 // }

    //                 // if (isNaN(progress)) {
    //                 //     console.error("Progress is NaN, setting to 0");
    //                 //     progress = 0; // or set to an appropriate default value
    //                 // }



    //                 formData.append('progres', progress);
    //                 formData.append('module', location.state.idmodule);
    //                 formData.append('apprenant', user.id);
    //                 promises.push(
    //                     axiosClient.post('progres/', formData)
    //                         .catch((error) => {
    //                             if (error.response.status === 400) {
    //                                 // Handle the 400 Bad Request error silently
    //                                 console.log(''); // or do nothing
    //                             } else {
    //                                 // Log or handle other errors as desired
    //                                 // console.error();
    //                             }
    //                         })
    //                 );
    //                 // Toast notification
    //                 toast({
    //                     title: 'Terminé!',
    //                     position: 'top-right',
    //                     isClosable: true,
    //                 });

    //                 // Update existing progress if needed
    //                 // Check if progres is an array
    //                 if (Array.isArray(progres)) {
    //                     progres.forEach((prog) => {
    //                         if (prog.module === location.state.idmodule && prog.apprenant === user.id) {
    //                             prog.progres += progress;
    //                             const progressFormData = new FormData();
    //                             progressFormData.append('progres', prog.progres);
    //                             promises.push(axiosClient.patch(`/progres/${prog.id}/`, progressFormData));
    //                         }
    //                     });
    //                 } else {
    //                     // console.error("Progres is not an array:", progres);
    //                 }


    //                 // Wait for all promises to complete
    //                 Promise.all(promises)
    //                     .then((responses) => {
    //                         // console.log(responses);
    //                     })
    //                     .catch((error) => {
    //                         // console.error("Error updating progress:", error);
    //                     });
    //             })
    //             .catch((error) => {
    //                 // console.error("Error updating media progress:", error);
    //             });
    //     };

    //     return pmc.map((val) => {
    //         if (val.apprenant === idApp && val.media === idMedia) {
    //             // Check if the media is completed
    //             const isCompleted = completedMedia.has(idMedia) || val.etat;
    //             // console.log("isCompleted", idMedia);

    //             if (isCompleted) {
    //                 return (
    //                     <Button key={val.id} isDisabled bg={"green"}>
    //                         <Flex alignItems="center">
    //                             <Icon color={"white"} as={FaCheckCircle} mr="2" />
    //                             <Text color={"white"}>Terminé</Text>
    //                         </Flex>
    //                     </Button>
    //                 );
    //             } else {
    //                 return (
    //                     <Button key={val.id} bg={"#FFD24C"} onClick={() => handleButtonClick(val)}>
    //                         Marquer comme terminé
    //                     </Button>
    //                 );
    //             }
    //         }
    //         return null;
    //     });

    // };

    const checkPassed = (idMedia, idApp) => {
        const handleButtonClick = (val) => {
            // Update the local state immediately
            setCompletedMedia(prev => new Set(prev).add(idMedia));

            axiosClient
                .put(`progressmodcontent/${val.id}/`, {
                    etat: true,
                    media: idMedia,
                    apprenant: user.id,
                })
                .then(() => {
                    const promises = [];
                    const formData = new FormData();

                    // Prepare data for the request
                    formData.append('progres', progress);
                    formData.append('module', location.state.idmodule);
                    formData.append('apprenant', user.id);

                    // // Check if progress exists for the user and module
                    // if (progres && progres.apprenant === user.id && progres.module === location.state.idmodule) {
                    //     // Update existing progress
                    //     progres.progress += progress; // Update the progress value
                    //     const progressFormData = new FormData();
                    //     progressFormData.append('progres', progres.progress);
                    //     promises.push(axiosClient.patch(`/progres/${progres.id}/`, progressFormData)
                    //         .catch(() => {
                    //             // Suppress error logging
                    //         }));
                    // } else {
                    //     // Only make the POST request if there's no existing progress
                    //     promises.push(
                    //         axiosClient.post('progres/', formData)
                    //             .catch(() => {
                    //                 // Suppress error logging
                    //             })
                    //     );
                    // }

                    // Wait for all promises to complete
                    Promise.all(promises)
                        .then(() => {
                            // Handle successful responses if needed
                        })
                        .catch(() => {
                            // Suppress error logging
                        });
                })
                .catch(() => {
                    // Suppress error logging
                });
        };

        return pmc.map((val) => {
            if (val.apprenant === idApp && val.media === idMedia) {
                const isCompleted = completedMedia.has(idMedia) || val.etat;

                if (isCompleted) {
                    return (
                        <Button key={val.id} isDisabled bg={"green"}>
                            <Flex alignItems="center">
                                <Icon color={"white"} as={FaCheckCircle} mr="2" />
                                <Text color={"white"}>Terminé</Text>
                            </Flex>
                        </Button>
                    );
                } else {
                    return (
                        <Button key={val.id} bg={"#FFD24C"} onClick={() => handleButtonClick(val)}>
                            Marquer comme terminé
                        </Button>
                    );
                }
            }
            return null;
        });
    };
    const docss = [
        {
            uri: "https://api.paiperleckelearning.com/media/uploads/docs/industry_4.0-Revolution-PowerPoint-Templates.pptx"
            ,
            fileType: "ppt"
        } // Remote file
    ];




    const [doc, setDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Retrieve id from location state or a fallback value
    const idToFetch = idmed


    // console.log("the FCDoc", doc);



    // console.log("tidf", idToFetch);


    useEffect(() => {
        const fetchDocById = async () => {
            try {
                setLoading(true); // Set loading state to true
                const response = await axiosClient.get(`currentDoc/?id=${idToFetch}`);
                setDoc(response.data); // Set the document data
            } catch (err) {
                setError(err.message); // Set error if there is an issue
            } finally {
                setLoading(false); // Set loading state to false after fetching
            }
        };

        fetchDocById(); // Call the function to fetch document
    }, [idToFetch]);


    // console.log("idMediaXXXXXXX", doc && doc.id, user.id);




    const nav = useNavigate()

    if (loading) {
        const defaultOptions = {
            loop: true, // Set to false if you want it to play once
            autoplay: true, // Starts playing the animation automatically
            animationData: animationData, // The imported animation JSON file
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice', // Adjust the aspect ratio as needed
            },
        };

        return (
            <Flex justify="center" align="center" height="100vh">
                <Lottie options={defaultOptions} height={200} width={200} />
            </Flex>
        );
    }

    // Show an error message if there is an error
    if (error) {
        return <div>Error: {error}</div>;
    }



    // console.log("doccccccc");

    return (



        <>

            <Flex justifyContent={'flex-end'}>
                <Button bg={"#089bd7"} px={{ base: '4', md: '6' }} width={"10%"} onClick={() => nav((-1))}>
                    <Flex alignItems="center">
                        <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                        <Text color={'#ffd140'}>Retour</Text>
                    </Flex>
                </Button>
            </Flex>


            {/* https://www.slideshare.net/slideshow/embed_code/key/jj2LfrdK5yuair?hostedIn=slideshare&page=upload */}
            <Box mt={"10px"} w="100%" h="630px">


                <>
                    {doc.typeDoc === "fichier" ? (
                        <>
                            <DocViewer documents={[{
                                uri: doc.file,
                                fileType: "pptx"
                            }]} pluginRenderers={DocViewerRenderers} />
                        </>
                    ) : doc.typeDoc === "lien" ? (
                        <>
                            <iframe
                                src={doc.lienPPT}
                                title="PDF Viewer"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                            ></iframe>


                        </>
                    ) : null}
                    <Flex justifyContent="end" mr="3" mt="10px">
                        {checkPassed(doc.id, user.id)}


                    </Flex>
                </>

            </Box>
        </>
    )
}
