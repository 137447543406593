import {
    Flex,
    Box,
    Image,
    useColorModeValue,
    Button,
    SimpleGrid,
    Text,
    CardFooter,
    CardBody,
    Card,
    Stack,
    Heading,
    Spinner,
    InputLeftElement,
    Input,
    Spacer,
    InputGroup,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Lottie from 'react-lottie';
import animationData from '../../assets/lot/learning.json';
import { useStateContext } from '../../context/ContextProvider';
import { SearchIcon } from '@chakra-ui/icons';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Formation_ = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [formData, setFormData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = useStateContext();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await axiosClient.get(`filtered-formations/?search=${searchQuery}&page=${currentPage}`);
                setFormData(res.data.results);
                setTotalPages(res.data.total_pages);
            } catch (error) {
                console.error("Error fetching formations:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [searchQuery, currentPage]);

    const openModules = (ids, titre) => {
        navigate('/modules', { state: { id: ids, titre, us: user.id } });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };



    // console.log("teh fuuuuformData", formData);
    const inputBg = useColorModeValue("white", "gray.700");


    return (
        <Box>

            <Box display="flex" justifyContent="space-between">
                {/* <Box display="flex" justifyContent="flex-start">

                    <Flex alignItems="center">
                        <Lottie height={80} width={80} options={defaultOpt} />
                        <GradientText gradient="#ffd140, #089bd7">
                            Formation {location.state.titre}</GradientText>                    </Flex>
                </Box> */}
                <Spacer flex="1" />
                <Box>
                    <InputGroup width="100%" size="md" borderRadius="md">
                        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                        <Input
                            value={searchQuery}
                            bg={inputBg}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            type="text"
                            placeholder="Chercher des formations"
                            borderRadius="md"
                            py={3}
                            pl={10}
                            boxShadow="md"
                            _focus={{ outline: "none", boxShadow: "outline" }}
                        /></InputGroup>
                </Box>
            </Box>
            {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height="83vh">
                    <Spinner size="xl" />
                </Flex>
            ) : formData.length === 0 ? (
                <Flex justifyContent="center" alignItems="center" flexDirection="column">
                    <Lottie height={400} width={550} options={defaultOptions} />
                    <Text fontSize={"2xl"} color={"#1A365D"} fontFamily={"mono"} textAlign="center">
                        Aucune formation n'a été trouvée !
                    </Text>
                </Flex>
            ) : (
                <>
                    <Box height={"100%"} overflowY="auto">
                        <SimpleGrid mt={10} columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 3 }}>
                            {formData.map((val, key) => (
                                <Card mx={5} key={key}>
                                    <CardBody>
                                        <Image src={val.image} height="180px" width="100%" alt={val.titre} borderRadius='lg' />
                                        <Stack mt='3' spacing='3'>
                                            <Heading size='md'>{val.titre}</Heading>
                                            <Text fontSize='xs' style={{ color: "#809fb8" }}>{val.description}</Text>
                                        </Stack>
                                    </CardBody>
                                    <CardFooter style={{ justifyContent: "end" }}>
                                        <Button onClick={() => openModules(val.id, val.titre)} bg={"#FFD24C"}>Commencer</Button>
                                    </CardFooter>
                                </Card>
                            ))}
                        </SimpleGrid>
                    </Box>

                    {/* Enhanced Pagination */}
                    <Flex justifyContent="center" alignItems="center" mt={6}>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            isDisabled={currentPage === 1}
                            colorScheme="yellow"
                            variant="outline"
                            mr={2}
                            leftIcon={<span>&lt;</span>} // Left Arrow
                        >
                            Précédent
                        </Button>
                        <Text mx={2} fontSize="lg" fontWeight="bold" color="yellow.500">
                            Page {currentPage} sur {totalPages}
                        </Text>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            isDisabled={currentPage === totalPages}
                            colorScheme="yellow"
                            variant="outline"
                            ml={2}
                            rightIcon={<span>&gt;</span>} // Right Arrow
                        >
                            Suivant
                        </Button>
                    </Flex>
                </>
            )}
        </Box>
    );
};

export default Formation_;
