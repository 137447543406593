// Chakra imports
import {
    Box,
    Button,
    Flex,

    Image,

    Text,
    useColorMode,
    useToast,
    Heading,
    Stack,
    SimpleGrid,
    Progress,
    useColorModeValue,
    ButtonGroup,
    Card, CardBody, CardFooter, InputGroup, InputLeftElement, Input, Spacer,
    Spinner,
    Badge,
    Circle

} from "@chakra-ui/react";
import Progres from './Progres'

import axiosClient from "../../axios-client";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useStateContext } from "../../context/ContextProvider";



import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IoDocumentsSharp } from "react-icons/io5";
import DemandeAcces from "./DemandeAcces";
import { ImCancelCircle } from "react-icons/im";
import { SearchIcon, StarIcon, WarningIcon } from "@chakra-ui/icons";
import Lottie from "react-lottie";
import bookData from '../../assets/lot/book.json'
import animationData from '../../assets/lot/learning.json'
import edata from '../../assets/lot/loadash.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOpt = {
    loop: true,
    autoplay: true,
    animationData: edata,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


function Modules_() {
    const [searchQuery, setSearchQuery] = useState('');
    const [listChapitres, setListChapitres] = useState([])
    const [listModule, setListModule] = useState([])
    const [listProgres, setListProgres] = useState([])
    const [listAcces, setListAcces] = useState([])
    const [listCertificats, setListCertificats] = useState([])
    const [listResultat, setListResultat] = useState([])

    const [idA, setIdA] = useState(1)

    const toast = useToast()
    const [filterprog, setFP] = useState([])
    const [listFormation, setListFormation] = useState([])
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state


    const [loading, setLoading] = useState(false);

    // const fetchData = useCallback(async () => {
    //     try {
    //         setLoading(true);
    //         const progressRes = await axiosClient.get('progres/');
    //         const accessRes = await axiosClient.get('acces/');
    //         setListProgres(progressRes.data);
    //         setListAcces(accessRes.data);
    //         setLoading(false);
    //     } catch (error) {
    // console.log(error);
    //         setLoading(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     let timer = null;
    //     if (!loading) {
    //         timer = setTimeout(() => {
    //             fetchData();
    //         }, 300); // Delay API call by 500ms
    //     }
    //     return () => clearTimeout(timer);
    // }, [fetchData, loading]);

    const { user, setUser } = useStateContext();

    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
                // console.log("ddddd", data);

            })
    }, [])



    const us = sessionStorage.getItem('userid');
    const currentuser = user.id // Convert to integer

    // console.log("currentuser", currentuser);


    useEffect(() => {
        axiosClient.get('chapitre/')
            .then((res) => setListChapitres(res.data))
    }, [])
    useEffect(() => {
        const fetchProgress = async () => {
            const apprenantId = user.id; // Assuming user is defined in your component

            // Check if both parameters are provided
            if (!apprenantId) {
                // console.error("Apprenant ID  are required.");
                return;
            }

            try {
                const res = await axiosClient.get('cprogressS/', {
                    params: { apprenant: apprenantId }
                });
                const data = res.data;

                // Set the progress directly from the response
                setListProgres(data.progress);
            } catch (error) {
                // console.error("Error fetching progress data:", error);
            }
        };

        fetchProgress();
    }, [user.id])


    // useEffect(() => {
    //     const fetchAllAcces = async () => {
    //         let allAcces = [];
    //         let page = 1;
    //         let hasMore = true;

    //         while (hasMore) {
    //             try {
    //                 const res = await axiosClient.get('acces/', { params: { page } });
    //                 const data = res.data;

    //                 // Add the current page results to the allAcces array
    //                 allAcces = allAcces.concat(data.results); // Assuming the response contains a 'results' array

    //                 // Check if there are more pages
    //                 hasMore = data.next !== null; // Assuming 'next' is a URL or null if no more pages
    //                 page += 1; // Move to the next page
    //             } catch (error) {
    //                 console.error("Error fetching acces data:", error);
    //                 hasMore = false; // Stop fetching on error
    //             }
    //         }

    //         // Set the complete list of acces after fetching all pages
    //         setListAcces(allAcces);

    //         // Filter the acces based on the current user
    //         setListAcces(allAcces.filter(e => e.apprenant === currentuser));
    //     };

    //     fetchAllAcces();
    // }, [currentuser]);
    useEffect(() => {
        const fetchAccessData = async () => {
            if (!currentuser) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tacces/?user_id=${currentuser}`);
                setListAcces(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching access data');
            }
        };

        fetchAccessData();
    }, [currentuser]);


    // useEffect(() => {
    //     axiosClient.get('progres/')
    //         .then((res) => set(res.data.filter(e => e.apprenant === user.id)))
    // }, [])



    const getAccesbyApprenant = (idA, idM) => {
        let a = "error"
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                if ((val.etat === false && val.encours === true && val.refus === false)) {
                    a = "en cours"
                }
                if (val.etat === false && val.encours === false && val.refus === true) {
                    a = "reactiver"
                }
                if (val.etat === true && val.encours === false && val.refus === false) {
                    a = "commencer"
                }
                if ((val.etat === false && val.encours === true && val.refus === true)) {
                    a = "en cours d'activation"
                }
            }
        })
        return a
    }

    // console.log("LPPCVVXXX", listProgres);

    const getProgresbyApprenant = (idA, idM) => {
        let e = "error"
        let a
        listProgres.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                e = val.progres
                a = e.toFixed(0)

            }
        })

        return a
    }

    // useEffect(() => {
    //     axiosClient.get('module/')
    //         .then((res) => {
    //             setListModule(res.data.filter(e => e.etat === true))
    //             setIsLoading(false)
    //         })

    //         .catch((error) => {
    //             setIsLoading(false); // Set isLoading to false even if there's an error
    //         });
    // }, [])
    // useEffect(() => {
    //     axiosClient.get('formation/')
    //         .then((res) => {
    //             setListFormation(res.data)
    //         })
    // }, [])



    // useEffect(() => {
    //     axiosClient.get('certificat/')
    //         .then((res) => setListCertificats(res.data))
    // }, [])
    // useEffect(() => {
    //     axiosClient.get('resultat/')
    //         .then((res) => setListResultat(res.data))
    // }, [])





    const [setAvatarUrl] = useState('');

    const [apprenant, setApprenant] = useState('');

    // useEffect(() => {
    //     axiosClient.get(`/apprenants/${user.id}/image`)
    //         .then(response => {
    //             setAvatarUrl(response.request.responseURL);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }, []);

    useEffect(() => {
        if (user && user.id) {
            axiosClient.get(`/apprenants/${user.id}/`)
                .then(({ data }) => {
                    setApprenant(data);
                });
        }
    }, [user]);


    const [moduleApprenant, setmoduleApprenant] = useState([])

    useEffect(() => {
        if (user && user.id) {
            axiosClient.get(`acces/getModuleById/?idApprenant=${user.id}`)
                .then(({ data }) => {
                    setmoduleApprenant(data);
                });
        }
    }, [user]); // Add user as a dependency






    function truncateDescription(description) {
        const words = description.split(' ');
        const truncatedWords = words.slice(0, 30);
        return truncatedWords.join(' ') + (words.length > 15 ? '...' : '');
    }


    function openModContent(ids, titre, titreFormation, desc, formation, progress) {
        navigate('/chapitres', {
            state: {
                id: ids,
                titre: titre,
                titreF: titreFormation,
                description: desc,
                idf: formation,
                prog: progress
            }
        })
    }

    const annulerDamandeAcces = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.delete(`acces/${id}/`)
            .then((res) => {


            })
            .catch((error) => {

            })
    }

    const navigate = useNavigate()

    const annulerReactivation = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.patch(`acces/${id}/`, {
            etat: false,
            encours: false,
            refus: true
        })
            .then((res) => {

            })
            .catch((error) => {
            })
    }


    const reactiver = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.patch(`acces/${id}/`, {
            encours: true,
        })
            .then((res) => {

            })
            .catch((error) => {
            })
    }





    const [listRatingwithIds, setListRatingwithIds] = useState([]);


    const getIDM = () => {
        {

            let id = 0
            listFormation.map((vf, kf) => {
                listModule.map((v, k) => {
                    moduleApprenant.map((val, key) => {
                        if (vf.id === v.formation) {
                            if (v.id === val.module) {

                                id = v.id
                            }
                        }
                    })
                })
            })
            return id;
        }
    }

    // useEffect(() => {
    //     axiosClient
    //         .get(
    //             `/rating/getRatingsByIds/?idModule=${getIDM()}&idApprenant=${user.id}`
    //         )
    //         .then((res) => setListRatingwithIds(res.data));

    // }, []);




    // const filteredModules = listModule.filter((val) => {

    //     const ChapitresOfModules = listChapitres.filter((chapitre) => chapitre.module === val.id);

    //     return ChapitresOfModules.length > 0 && val.titre.toLowerCase().includes(searchQuery.toLowerCase());
    // });

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);





    useEffect(() => {
        if (user && user.id) { // Ensure user is loaded and has an ID
            setIsLoading(true); // Set loading to true before the API call
            axiosClient.get(`amodules/?searchQuery=${searchQuery}&page=${currentPage}&apprenantId=${user.id}`)
                .then((res) => {
                    setListModule(res.data.results);
                    setTotalPages(res.data.total_pages);
                })
                .catch((error) => {
                    // console.error("Error fetching modules:", error);
                })
                .finally(() => {
                    setIsLoading(false); // Reset loading state
                });
        }
    }, [searchQuery, currentPage, user]); // Include user in dependencies

    // console.log("lmmmm", listModule);


    const inputBg = useColorModeValue("white", "gray.700");
    function truncateDescription(description) {
        const words = description.split(' ');
        const truncatedWords = words.slice(0, 30);
        return truncatedWords.join(' ') + (words.length > 15 ? '...' : '');
    }

    function GradientText({ children, gradient }) {
        const gradientBg = {
            background: `linear-gradient(to left, ${gradient})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        };

        return (
            <Text ml={2} mt={2} fontWeight={"bold"} fontSize={"28px"} as="span" sx={gradientBg}>
                {children}
            </Text>
        );
    }
    // const isExistModule = listAcces.length > 0 && filteredModules.length > 0 ? (



    //     <Box height={"600px"} overflowY="auto" css={{
    //         "&::-webkit-scrollbar": {
    //             width: "9px",
    //             height: "6px",

    //         },
    //         "&::-webkit-scrollbar-thumb": {
    //             background: "#089bd7",
    //             borderRadius: "6px",

    //         },
    //         "&::-webkit-scrollbar-track": {
    //             background: "gray.50",
    //         },
    //     }} >

    //         <SimpleGrid mt={5} spacing={4} columns={{ base: 1, md: 2, lg: 3 }} >
    //             {listFormation.map((vf, kf) => {
    //                 return filteredModules.filter(val => (getAccesbyApprenant(apprenant.id, val.id) === "commencer" || getAccesbyApprenant(apprenant.id, val.id) === "reactiver" || getAccesbyApprenant(apprenant.id, val.id) === "en cours d'activation")).map((v, k) => {
    //                     {
    //                         return moduleApprenant.map((val, key) => {

    //                             if (vf.id === v.formation) {
    //                                 if (v.id === val.module) {


    //                                     return (

    //                                         <Card mx={5} >
    //                                             <CardBody >
    //                                                 <Image

    //                                                     src={v.image}
    //                                                     height="180px"
    //                                                     width="100%"
    //                                                     alt='Green double couch with wooden legs'
    //                                                     borderRadius='lg'
    //                                                 />

    //                                                 <Stack mt='3' spacing='3'>
    //                                                     {
    //                                                         getProgresbyApprenant(apprenant.id, v.id) === "error"
    //                                                             ? <Progres />
    //                                                             : getAccesbyApprenant(apprenant.id, v.id) === "reactiver" || getAccesbyApprenant(apprenant.id, v.id) === "en cours d'activation"
    //                                                                 ? <Progres color={'red'} value={getProgresbyApprenant(apprenant.id, v.id)} a={getAccesbyApprenant(apprenant.id, v.id)} />
    //                                                                 : getAccesbyApprenant(apprenant.id, v.id) === "commencer"
    //                                                                     ? <Progres color={'green'} value={getProgresbyApprenant(apprenant.id, v.id)} />
    //                                                                     : null
    //                                                     }
    //                                                     <Heading size='md'>{v.titre}</Heading>
    //                                                     <Text fontSize='xs' style={{ color: "#809fb8" }}>{truncateDescription(v.description)}</Text>
    //                                                 </Stack>
    //                                             </CardBody>
    //                                             <CardFooter style={{ justifyContent: "end" }} >
    //                                                 <ButtonGroup spacing='1' >
    //                                                     {getAccesbyApprenant(apprenant.id, v.id) === "error"
    //                                                         ? <DemandeAcces idApprenant={apprenant.id} idModule={v.id} />
    //                                                         : getAccesbyApprenant(idA, v.id) === "en cours" || getAccesbyApprenant(apprenant.id, v.id) === "en cours d'activation"
    //                                                             ? <Stack direction='row' spacing={4} align='center'>
    //                                                                 {getAccesbyApprenant(apprenant.id, v.id) === "en cours"
    //                                                                     ? <Button style={{ fontSize: "22px", color: "red" }} onClick={() => annulerDamandeAcces(apprenant.id, v.id)}><ImCancelCircle /></Button>
    //                                                                     : <Button style={{ fontSize: "22px", color: "red" }} onClick={() => annulerReactivation(apprenant.id, v.id)}><ImCancelCircle /></Button>}

    //                                                                 <Button
    //                                                                     isLoading
    //                                                                     loadingText='Traitement en cours'
    //                                                                     colorScheme='teal'
    //                                                                     variant='outline'
    //                                                                     spinnerPlacement='start'
    //                                                                 >
    //                                                                 </Button>
    //                                                             </Stack>
    //                                                             : getAccesbyApprenant(apprenant.id, v.id) === "reactiver"
    //                                                                 ? <Button colorScheme='red' onClick={() => reactiver(apprenant.id, v.id)}>Reactiver</Button>
    //                                                                 : getAccesbyApprenant(apprenant.id, v.id) === "commencer"
    //                                                                     ? <Button onClick={() => openModContent(v.id, v.titre, vf.titre, v.description, v.formation, getProgresbyApprenant(apprenant.id, v.id))} colorScheme='green'>Commencer</Button>
    //                                                                     : null

    //                                                     }
    //                                                 </ButtonGroup>


    //                                             </CardFooter>
    //                                         </Card>
    //                                     )

    //                                 }
    //                             }

    //                         })

    //                     }


    //                 })
    //             })
    //             }


    //         </SimpleGrid>

    //     </Box>


    // ) : (

    //     <>
    //         <Box >
    //             <Flex mt={6}>
    //                 <Box display="flex" justifyContent="flex-start">


    //                 </Box>
    //                 <Spacer flex="1" />
    //             </Flex>
    //             <Lottie height={400} width={550} options={defaultOptions} />
    //             <Text fontSize={"2xl"} color={"#1A365D"} fontFamily={"mono"} textAlign="center" alignItems="center" justifyContent="center">Aucun module n'a été trouvé</Text>
    //         </Box>
    //     </>


    // );








    // console.log("listModule.length", listModule.length);



    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };



    const noModulesFound = searchQuery.trim() !== "" && listModule.length === 0
    const [requestActivationOngoing, setRequestActivationOngoing] = useState([]);
    // Function to create a new access request
    const postAccessRequest = async (moduleId, apprenantId) => {
        try {
            setLoadingId(moduleId); // Set loading state for this module
            const response = await axiosClient.post('accescases/', {
                idModule: moduleId,
                idApprenant: apprenantId,
                typeAccess: 'accessRequest'
            });
            // console.log("Access request created successfully:", response.data);
            setGrantedAccess(prev => [...prev, moduleId]); // Add moduleId to granted access list
        } catch (error) {
            // console.error("Error creating access request:", error);
            // Handle error feedback here
        } finally {
            setLoadingId(null); // Reset loading state
        }
    };
    const [loadingId, setLoadingId] = useState(null); // Track which request is loading
    const [ongoingAccess, setOngoingAccess] = useState([]); // Track granted access modules
    const [reactivationAccess, setReactivationAccess] = useState([]); // Track granted access modules
    const [grantedAccess, setGrantedAccess] = useState([]); // Track granted access modules
    const [prohibitedAcces, setProhibitedAccess] = useState([]); // Track granted access modules
    // Function to create a new access request

    // Function to update an existing access request
    const updateAccessRequest = async (moduleId, apprenantId) => {
        try {
            setLoadingId(moduleId); // Set loading state for this module
            const response = await axiosClient.put('accescases/', {
                idModule: moduleId,
                idApprenant: apprenantId,
                typeAccess: 'activationRequest'
            });

            window.location.reload(); // Refresh the page
            // console.log("Access request updated successfully:", response.data);
            // Handle any updates to your state here
        } catch (error) {
            // console.error("Error updating access request:", error);
            // Handle error feedback here
        } finally {
            setLoadingId(null); // Reset loading state
        }
    };
    // console.log("The LP", listProgres);
    const fetchAccessData = async () => {
        if (!user?.id) {
            return; // Exit early if user.id doesn't exist
        }

        try {
            const response = await axiosClient.get(`tacces/?user_id=${user.id}`);
            const accessData = response.data;

            // Handle access statuses
            const grantedAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                access.etat === true &&
                access.encours === false &&
                access.refus === false
            );

            const ongoingAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                access.encours
            );

            const reactivationAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                !access.encours &&
                access.refus === true
            );

            const prohibitedAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                !access.encours &&
                access.refus === true
            );

            const ongoingReactivationModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                access.encours
            );

            // Update state
            setRequestActivationOngoing(ongoingReactivationModules);
            setGrantedAccess(grantedAccessModules);
            setOngoingAccess(ongoingAccessModules);
            setReactivationAccess(reactivationAccessModules);
            setProhibitedAccess(prohibitedAccessModules);
        } catch (error) {
            // console.error('Error fetching access data', error);
        }
    }
    useEffect(() => {
        fetchAccessData(); // Initial fetch

        const intervalId = setInterval(fetchAccessData, 30000); // Poll every 30 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [user]);



    return (
        <>
            <Box>

                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="flex-start">

                        <Flex alignItems="center">
                            <Lottie height={80} width={80} options={defaultOpt} />
                            <GradientText gradient="#ffd140, #089bd7">
                                Mon apprentissage</GradientText>                    </Flex>
                    </Box>
                    <Spacer flex="1" />
                    <Box>
                        <InputGroup width="100%" size="md" borderRadius="md">
                            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                            <Input
                                value={searchQuery}
                                bg={inputBg}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                type="text"
                                placeholder="Recherche Modules"
                                borderRadius="md"
                                py={3}
                                pl={10}
                                boxShadow="md"
                                _focus={{ outline: "none", boxShadow: "outline" }}
                            /></InputGroup>
                    </Box>
                </Box>


                {isLoading ? (
                    <Flex justifyContent="center" alignItems="center" height="400px">
                        <Spinner size="xl" />
                    </Flex>
                ) : noModulesFound ? (
                    // Show the message only when the search query is provided and no formations are found
                    <>
                        <Lottie height={400} width={550} options={defaultOptions} />
                        <Text fontSize={"2xl"} color={"#1A365D"} fontFamily={"mono"} textAlign="center" alignItems="center" justifyContent="center">Aucune formation n'a été trouvée !</Text>
                    </>
                ) : (
                    // Show the filtered formations when available
                    <>
                        <Box height={"600px"} overflowY="auto" css={{
                            "&::-webkit-scrollbar": {
                                width: "9px",
                                height: "6px",

                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "#089bd7",
                                borderRadius: "6px",

                            },
                            "&::-webkit-scrollbar-track": {
                                background: "gray.50",
                            },
                        }} >

                            <>
                                {listModule.length > 0 ? (
                                    <SimpleGrid mt={37} columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 3 }}>
                                        {listModule
                                            .filter(val =>
                                                getAccesbyApprenant(apprenant.id, val.id) === "commencer" ||
                                                getAccesbyApprenant(apprenant.id, val.id) === "reactiver" ||
                                                getAccesbyApprenant(apprenant.id, val.id) === "en cours d'activation"
                                            )
                                            .map((v) => {
                                                return moduleApprenant.map((val) => {
                                                    if (v.id === val.module) {
                                                        const modLoad = loadingId === v.id || grantedAccess.includes(v.id); // Check if loading or granted access

                                                        return (
                                                            <Card mx={5} key={v.id}>
                                                                <CardBody>
                                                                    <Image
                                                                        src={v.image}
                                                                        height="180px"
                                                                        width="100%"
                                                                        alt='Module Image'
                                                                        borderRadius='lg'
                                                                    />
                                                                    {listAcces.map((acces, key) => {
                                                                        if (v.id === acces.module && getAccesbyApprenant(apprenant.id, v.id) === "commencer") {

                                                                            if (getProgresbyApprenant(apprenant.id, v.id) === '100') {
                                                                                return (

                                                                                    <React.Fragment key={acces.id}>
                                                                                        <Badge rounded="full" position="absolute"
                                                                                            top={6}
                                                                                            left={6} fontSize="0.8em" colorScheme="green">
                                                                                            Terminé
                                                                                        </Badge>
                                                                                        <Circle
                                                                                            size="10px"
                                                                                            position="absolute"
                                                                                            top={6}
                                                                                            right={6}
                                                                                            bg="green.200"
                                                                                        />
                                                                                    </React.Fragment>


                                                                                )
                                                                            } else {
                                                                                return (

                                                                                    <React.Fragment key={acces.id}>
                                                                                        <Badge rounded="full" position="absolute"
                                                                                            top={6}
                                                                                            left={6} fontSize="0.8em" colorScheme="green">
                                                                                            En cours
                                                                                        </Badge>
                                                                                        <Circle
                                                                                            size="10px"
                                                                                            position="absolute"
                                                                                            top={6}
                                                                                            right={6}
                                                                                            bg="green.200"
                                                                                        />
                                                                                    </React.Fragment>


                                                                                )
                                                                            }
                                                                            // return (
                                                                            //     <>
                                                                            //         {getProgresbyApprenant(idA, val.id) }
                                                                            //     </>
                                                                            // );

                                                                        } else {

                                                                        }
                                                                    })
                                                                    }
                                                                    <Stack mt='3' spacing='3'>
                                                                        {getProgresbyApprenant(apprenant.id, v.id) === "error" ? (
                                                                            <Progres />
                                                                        ) : (
                                                                            (getAccesbyApprenant(apprenant.id, v.id) === "reactiver" ||
                                                                                getAccesbyApprenant(apprenant.id, v.id) === "en cours d'activation" ||
                                                                                getAccesbyApprenant(apprenant.id, v.id) === "commencer") ? (
                                                                                <Progres color={'green'} value={getProgresbyApprenant(apprenant.id, v.id)} />
                                                                            ) : null
                                                                        )}
                                                                        <Heading size='md'>{v.titre}</Heading>
                                                                        <Text fontSize='xs' style={{ color: "#809fb8" }}>{truncateDescription(v.description)}</Text>
                                                                    </Stack>
                                                                </CardBody>
                                                                <CardFooter style={{ justifyContent: "end" }}>
                                                                    <Stack direction='row' spacing={4} align='center'>
                                                                        {modLoad ? (
                                                                            <Flex
                                                                                alignItems="center"
                                                                                border="1px"
                                                                                borderColor="yellow.500"
                                                                                borderRadius="md"
                                                                                padding="2"
                                                                                backgroundColor="yellow.100"
                                                                            >
                                                                                <Spinner size="sm" mr={2} color="teal.500" />
                                                                                <Text color="yellow.600" fontWeight="bold">Demande en cours...</Text>
                                                                            </Flex>
                                                                        ) : grantedAccess.some(access => access.module === v.id) ? (
                                                                            <Button
                                                                                colorScheme='teal'
                                                                                variant='solid'
                                                                                onClick={() => openModContent(v.id, v.titre, v.description, v.formation, getProgresbyApprenant(apprenant.id, v.id))}
                                                                            >
                                                                                Commencer
                                                                            </Button>
                                                                        ) : ongoingAccess.some(access => access.module === v.id) ? (
                                                                            <Flex
                                                                                alignItems="center"
                                                                                border="1px"
                                                                                borderColor="yellow.500"
                                                                                borderRadius="md"
                                                                                padding="2"
                                                                                backgroundColor="yellow.100"
                                                                            >
                                                                                <Spinner size="sm" mr={2} color="yellow.600" />
                                                                                <Text color="yellow.600" fontWeight="bold">Demande en cours...</Text>
                                                                            </Flex>
                                                                        ) : requestActivationOngoing.some(access => access.module === v.id) ? (
                                                                            <Flex
                                                                                alignItems="center"
                                                                                border="1px"
                                                                                borderColor="blue.500"
                                                                                borderRadius="md"
                                                                                padding="2"
                                                                                backgroundColor="blue.100"
                                                                            >
                                                                                <Spinner size="sm" mr={2} color="blue.600" />
                                                                                <Text color="blue.600" fontWeight="bold">En cours de réactivation...</Text>
                                                                            </Flex>
                                                                        ) : reactivationAccess.some(access => access.module === v.id) ? (
                                                                            <Flex
                                                                                alignItems="center"
                                                                                border="1px"
                                                                                borderColor="red.500"
                                                                                borderRadius="md"
                                                                                padding="2"
                                                                                backgroundColor="red.100"
                                                                                cursor="pointer"
                                                                                onClick={() => updateAccessRequest(v.id, apprenant.id)}
                                                                            >
                                                                                <WarningIcon color="red.500" mr={2} />
                                                                                <Text color="red.600" fontWeight="bold">Accès bloqué (réactiver)</Text>
                                                                            </Flex>
                                                                        ) : (
                                                                            <Button
                                                                                colorScheme='teal'
                                                                                variant='outline'
                                                                                onClick={() => postAccessRequest(v.id, apprenant.id)}
                                                                            >
                                                                                Demandez l'accès
                                                                            </Button>
                                                                        )}
                                                                    </Stack>
                                                                </CardFooter>
                                                            </Card>
                                                        );
                                                    }
                                                    return null; // Return null for unmatched conditions
                                                });
                                            })}
                                    </SimpleGrid>
                                ) : (
                                    <Flex mt={10} justifyContent="center" alignItems="center" flexDirection="column">
                                        <Lottie height={400} width={550} options={defaultOptions} />
                                        <Text fontSize={"2xl"} color={"#1A365D"} fontFamily={"mono"} textAlign="center">
                                            Aucun module n'a été trouvée !
                                        </Text>
                                    </Flex>
                                )}
                            </>



                        </Box>
                    </>
                )}


                {listModule.length > 0 && <Flex justifyContent="center" alignItems="center" mt={6}>
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        colorScheme="yellow"
                        variant="outline"
                        mr={2}
                        leftIcon={<span>&lt;</span>} // Left Arrow
                    >
                        Précédent
                    </Button>
                    <Text mx={2} fontSize="lg" fontWeight="bold" color="yellow.500">
                        Page {currentPage} sur {totalPages}
                    </Text>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        colorScheme="yellow"
                        variant="outline"
                        ml={2}
                        rightIcon={<span>&gt;</span>} // Right Arrow
                    >
                        Suivant
                    </Button>
                </Flex>}

            </Box>

        </>




    );

}
export default Modules_;