import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, CircularProgress, CircularProgressLabel, Flex, Icon, IconButton, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spinner, Stack, Text, Toast, useColorModeValue, useToast } from '@chakra-ui/react'

import React, { useCallback, useEffect, useState } from 'react'

import { FaCheckCircle, FaPaperPlane } from 'react-icons/fa'

import axiosClient from '../../../axios-client'

import { BiBadgeCheck } from 'react-icons/bi'

import { RiSlideshowFill } from 'react-icons/ri'

import { AiOutlineFileZip, AiOutlineLink } from 'react-icons/ai'

import { MdOndemandVideo, MdQuiz } from 'react-icons/md'

import Lottie from 'react-lottie'

import { useLocation, useNavigate } from 'react-router'

import bookData from '../../../assets/lot/book2.json'

import { useStateContext } from '../../../context/ContextProvider'

import { StarIcon } from '@chakra-ui/icons'


import rating from '../../../assets/lot/rating.json';

import Cookies from 'js-cookie';


const defaultOption = {

    loop: true,

    autoplay: true,

    animationData: rating,

    rendererSettings: {

        preserveAspectRatio: 'xMidYMid slice'

    }

};


const itemsPerPage = 5;


const defaultOpt = {

    loop: true,

    autoplay: true,

    animationData: bookData,

    rendererSettings: {

        preserveAspectRatio: 'xMidYMid slice'

    }

};

export default function Chapitre() {

    const [chapitres, setChapitres] = useState([])
    const location = useLocation()

    const [medias, setMedias] = useState([])

    const [link, setLink] = useState([])

    const [tests, setTests] = useState([])

    const [pmc, setProgModContent] = useState([]);

    const [listProgres, setListProgres] = useState([])

    const [progressId, setProgressId] = useState()

    const { user, setUser } = useStateContext();

    const [resultat, setResultat] = useState()

    const [certificat, setCertificat] = useState()

    const [isvalidate, setIsValidate] = useState([])

    const [listRatingwithIds, setListRatingwithIds] = useState([]);
    const [vds, setVds] = useState([])


    const [listefeeds, setListFeeds] = useState([]);
    const [listerates, setListRates] = useState([]);



    // useEffect(() => {

    //     axiosClient

    //         .get(

    //             `/rating/getRatingsByIds/?idModule=${location.state.id}&idApprenant=${user.id}`

    //         )

    //         .then((res) => setListRatingwithIds(res.data));

    // }, []);


    const [mcModules, setMCModules] = useState([]);
    useEffect(() => {
        // Check if user and user.id are available
        if (user && user.id && location.state?.id) {
            axiosClient
                .get(`/media_chapitres/?module_id=${location.state.id}&apprenant_id=${user.id}`)
                .then((res) => {
                    // console.log("API Response:", res.data); // Log response data
                    setMCModules(res.data); // Set state
                })
                .catch((error) => {
                    console.error('Error fetching media chapters:', error); // Handle errors
                });
        }
    }, [location.state?.id, user]); // Include user in dependencies



    // console.log("mcModulesmcModulesmcModulesmcModules", mcModules);



    useEffect(() => {

        axiosClient.get('auth/user/')

            .then(({ data }) => {

                setUser(data)

                // console.log()

            })

    }, [])


    // console.log("the     f  User ID", user.id);



    useEffect(() => {
        const fpd = async () => {
            if (!location.state.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`ttest/?module_id=${location.state.id}`);
                setTests(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching progres data');
            }
        };

        fpd();
    }, [location.state.id]);


    // console.log("the final tests gettes are the follwoing  : ", tests);






    useEffect(() => {
        const fpd = async () => {
            if (!user.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tres/?user_id=${user.id}`);
                setResultat(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching progres data');
            }
        };

        fpd();
    }, [user.id]);


    useEffect(() => {

        if (tests !== undefined && resultat !== undefined) {

            const validateMap = new Map();

            resultat.forEach((res) => {

                if (res.idTest === undefined) {

                    validateMap.set(res.idTest, false);

                } else {

                    validateMap.set(res.idTest, res.valider);

                }

            });

            const updatedValidate = tests.map((test) => {

                const validate = validateMap.get(test.id);

                return validate !== undefined ? validate : false;

            });

            setIsValidate(updatedValidate);

        }

    }, [tests, resultat]);








    useEffect(() => {

        axiosClient.get('link/')

            .then((res) => setLink(res.data))

    }, [])

    useEffect(() => {
        const fm = async () => {
            if (!location.state.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tmedia/?module_id=${location.state.id}`);
                setMedias(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching medias data');
            }
        };

        fm();
    }, [location.state.id]);



    // useEffect(() => {

    //     axiosClient.get('certificat/')

    //         .then((res) => {

    //             setCertificat(res.data)

    //         })

    // }, [])

    // useEffect(() => {

    //     axiosClient.get('videos/')

    //         .then((res) => {

    //             setVds(res.data)

    //         })

    // }, [])


    // useEffect(() => {

    //     axiosClient.get('progressmodcontent/')

    //         .then((res) => {

    //             setProgModContent(res.data)

    //         })

    // }, [])

    useEffect(() => {
        const fetchAllProgressModContent = async () => {
            let allPmc = [];

            // Check if user.id exists
            if (!user.id) {
                // console.warn("User ID is missing.");
                setLoading(false); // End loading if user ID is missing
                return; // Exit early if the user ID is not available
            }

            setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogressmod/', { params: { apprenant: user.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                // console.error("Error fetching progress mod content:", error);
            }

            // Set the progress mod content and end loading
            setProgModContent(allPmc);
            setLoading(false); // End loading after fetching
        };

        fetchAllProgressModContent();
    }, [user.id]); // Add user.id as a dependency
    useEffect(() => {
        const fp = async () => {
            let allPmc = [];

            // Check if user.id and location.state.id exist
            if (!user.id) {
                // console.warn("User ID is missing.");
                setLoading(false); // End loading if user ID is missing
                return; // Exit early if the user ID is not available
            }

            if (!location.state?.id) {
                // console.warn("Module ID is missing.");
                setLoading(false); // End loading if module ID is missing
                return; // Exit early if the module ID is not available
            }

            setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogress/', { params: { apprenant: user.id, module: location.state.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                console.error("Error fetching progress content:", error);
            }

            // Set the progress mod content and end loading
            setListProgres(allPmc);
            setLoading(false); // End loading after fetching
        };

        fp();
    }, [user.id, location.state?.id]);


    // useEffect(() => {




    //     if (user.id !== undefined) {
    //         axiosClient.get('rating/')

    //             .then((res) => {

    //                 setListRates(res.data.filter(

    //                     (val) => val.module === location.state.id &&
    //                         val.apprenant === user.id


    //                 ))

    //             })
    //     }
    // }, [user])

    // useEffect(() => {

    //     if (user.id !== undefined) {
    //         axiosClient.get('feedback/')

    //             .then((res) => {

    //                 setListFeeds(res.data.filter(

    //                     (val) => val.id_module === location.state.id &&
    //                         val.apprenant === user.id


    //                 ))

    //             })
    //     }

    // }, [user])







    const navigate = useNavigate()

    // console.log("    module I   1D ", location.state.id);

    const [totalPages, setTotalPages] = useState(0); // Track total number of pages
    const [currentCPage, setCurrentCPage] = useState(1); // Ensure this is initialized

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchChapters = async () => {
            const moduleId = location.state.id; // Extract module_id from location state

            try {
                // Fetch chapters by module ID with pagination
                const res = await axiosClient.get(`chapitres-by-module/?module_id=${moduleId}&page=${currentCPage}`);


                // console.log("res.data", res.data);
                setLoading(false)
                setChapitres(res.data.results); // Set the retrieved chapters in state
                setTotalPages(res.data.total_pages || 0);  // Set total pages from the response
            } catch (error) {
                console.error("There was an error fetching the chapters:", error);


                setLoading(false)

            }
        };

        fetchChapters();
    }, [currentCPage, location.state.id]);

    // const items = [1, 2, 3];
    // const unusedVar = 5; // This variable is unused

    // const transformedItems = items.map(item => item * 2);
    // console.log(transformedItems); // Output: [2, 4, 6]



    // console.log("all the chapters", chapitres);



    const handlePageChange = (newPage) => {
        setCurrentCPage(newPage);
    };

    function openModuleSubContentDoc(ids, idmod, idchap, titre, titreFormation, idMedia, chapitreCount) {

        navigate('/submodcontentdoc', {

            state: {

                id: ids,

                idmodule: idmod,

                idchapitre: idchap,

                tm: titre,

                tf: titreFormation,

                idm: idMedia,

                chapcount: chapitreCount

            }

        })

    }

    function openModuleSubContentPPt(ids, idmod, idchap, titre, titreFormation, idMedia, chapitreCount) {

        navigate('/ppt', {

            state: {

                id: ids,

                idmodule: idmod,

                idchapitre: idchap,

                tm: titre,

                tf: titreFormation,

                idm: idMedia,

                chapcount: chapitreCount

            }

        })

    }


    function LinkButton({ href, children }) {

        const handleClick = () => {

            window.open(href, "_blank");

        };

        return (

            <Button color="#fff" bg="#089bd7" onClick={handleClick}>

                {children}

            </Button>

        );

    }

    function LinkButtondb({ href, children }) {



        return (

            <Button isDisabled color="#fff" bg="#089bd7" >

                {children}

            </Button>

        );

    }


    // console.log("all medias , ", medias);


    const bg = useColorModeValue('#089bd7', 'gray.700');

    const sortedMedias = medias.sort((a, b) => {

        if (a.type === 'Video' && b.type !== 'Video') {

            return -1;

        } else if (a.type !== 'Video' && b.type === 'Video') {

            return 1;

        } else if (a.type === 'Video' && b.type === 'Video') {

            return 0;

        } else if (a.type === 'Link' && b.type !== 'Link') {

            return 1;

        } else if (a.type !== 'Link' && b.type === 'Link') {

            return -1;

        } else {

            return 0;

        }

    });




    const [itests, setItests] = useState([]);

    const fetchTests = async () => {
        if (!user || !user.id || !location.state?.id) return;

        try {
            const response = await axiosClient.get('tests_module/', {
                params: {
                    module_id: location.state.id,
                    user: user.id,
                },
            });
            setItests(response.data);
        } catch (error) {
            console.error("Error fetching tests:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTests(); // Fetch when the component mounts or user/location changes
    }, [user, location.state?.id]);
    // console.log("itests", itests);

    // console.log("  listProgress     : ", listProgres);
    // console.log("  tests     : ", tests);



    // useEffect(() => {
    //     console.log('Navigated back to MyComponent');

    //     // Optionally, handle any side effects here
    //     return () => {
    //         console.log('Leaving MyComponent');
    //     };
    // }, [location.key]); // The key changes on navigation, triggering the effect


    const testActivated = itests.map((test, index) => (
        <Stack
            key={test.id}
            h={{ base: "90px", md: "60px" }}
            w={{ base: "100%", md: "100%" }}
            borderRadius="lg"
            border="1px solid rgba(0, 0, 0, 0.25)"
            p={4}
            mb={3}
            direction="row"
            alignItems="center"
            bg={test.accessGranted ? "white" : "gray.100"}
            opacity={test.accessGranted ? 1 : 0.5}
            cursor={test.accessGranted ? "pointer" : "not-allowed"}
        >
            <Icon
                as={FaCheckCircle}
                color={test.validated ? "green.500" : "gray.400"}
                h="32px"
                w="32px"
                mr={{ base: "20px", md: "60px" }}
            />
            <Icon
                as={MdQuiz}
                color={test.accessGranted ? "black" : "gray.400"}
                h="32px"
                w="32px"
            />
            <Box flexGrow={1} display="flex">
                <Box ml={{ base: "10px", md: "30px" }}>
                    Test -- Niveau : {test.difficulter}
                </Box>
            </Box>
            <Button
                w="80px"
                color="#fff"
                bg={test.accessGranted ? "#089bd7" : "gray.500"}
                onClick={() => {
                    if (test.accessGranted) {
                        navigate(`/test/${test.id}`, {
                            state: {
                                mediac: mediasOfChapitresOfModules
                            }
                        });
                    }
                }}
                isDisabled={!test.accessGranted}
            >
                Accès
            </Button>
        </Stack>
    ));







    const [mediaCount, setMediaCount] = useState(0);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchMediaCount = async () => {
            try {
                const response = await axiosClient.get(`/media_count/?module_id=${location.state.id}`);
                setMediaCount(response.data.media_count);
            } catch (err) {
                setError(err.response ? err.response.data.error : 'An error occurred');
            }
        };

        if (location.state.id) {
            fetchMediaCount();
        }
    }, [location.state.id]);


    function mediasInsideChapitres() {

        let total = 0






        chapitres.map((val, key) => {

            if (val.module === location.state.id) {

                sortedMedias.map((media) => {

                    if (val.id === media.chapitre) {

                        total = total + 1

                    }


                })

            }

        })

        return total



    }







    const mediasOfChapitresOfModules = mediaCount




    // console.log("mediasOfChapitresOfModulesmediasOfChapitresOfModules", mediasOfChapitresOfModules);

    const [currentPage, setCurrentPage] = useState(1);


    const startIndex = (currentPage - 1) * itemsPerPage;

    const endIndex = startIndex + itemsPerPage;



    // useEffect(() => {

    //     const fetchProgress = async () => {

    //         try {

    //             const response = await axiosClient.get(

    //                 `progres/geProgress/?idModule=${location.state.id}&idApprennant=${user.id}`

    //             );

    //             const resData = response.data;

    //             const totalMedias = sortedMedias.length;

    //             let trueProgress = 0;

    //             chapitres.forEach((val) => {

    //                 if (val.module === location.state.id) {

    //                     sortedMedias.forEach((media) => {

    //                         if (media.chapitre === val.id) {

    //                             pmc.forEach((p) => {

    //                                 if (media.id === p.media && p.apprenant === user.id && p.etat === true) {

    //                                     trueProgress += 1;

    //                                 }

    //                             });

    //                         }

    //                     });

    //                 }

    //             });

    //             const newProgress = (trueProgress / totalMedias) * 100;

    //             if (newProgress !== resData.progres && resData.progres !== undefined) {

    //                 const formData = new FormData();

    //                 formData.append("progres", newProgress);

    //                 await axiosClient.patch(`/progres/${resData.id}/`, formData);

    //             }

    //             setProgressId(resData);

    //             setFinalProgres(newProgress);

    //         } catch (error) {

    // console.log(error);

    //         }

    //     };

    //     if (chapitres.length && sortedMedias.length && pmc.length && location.state.id) {

    //         fetchProgress();

    //     }

    // }, [chapitres, sortedMedias, pmc, location.state.id]);

    // console.log("location.state.prog", location.state.prog);



    const [progressLoad, setProgressLoad] = useState(true);
    const [finalProgres, setFinalProgres] = useState(() => {
        const storedProgress = localStorage.getItem(`progress_${location.state.id}_${user.id}`);
        return storedProgress ? parseFloat(storedProgress) : null;
    });

    useEffect(() => {
        const fetchProgress = async () => {
            if (!location.state.id || !user.id) {
                // console.log("moduleId or userId is missing");
                setProgressLoad(false);
                return;
            }

            try {
                const response = await axiosClient.get('progres/geProgress/', {
                    params: {
                        idModule: location.state.id,
                        idApprennant: user.id
                    }
                });

                const newProgress = response.data.actual_progress;
                setFinalProgres(newProgress);
                localStorage.setItem(`progress_${location.state.id}_${user.id}`, newProgress.toString());
            } catch (error) {
                console.error("Error fetching progress:", error);
            } finally {
                setProgressLoad(false);
            }
        };

        fetchProgress();
    }, [location.state.id, user.id]);


    // console.log("ffffffRRRROrgressss", finalProgres);


    function GradientText({ children, gradient }) {

        const gradientBg = {

            background: `linear-gradient(to left, ${gradient})`,

            WebkitBackgroundClip: 'text',

            WebkitTextFillColor: 'transparent',

        };

        return (

            <Text ml={2} mt={2} fontWeight={"bold"} fontSize={"28px"} as="span" sx={gradientBg}>

                {children}

            </Text>

        );

    }

    const ic = useColorModeValue('black', 'gray.200')

    const ic_ = useColorModeValue('green', 'green.200')


    const [states, setStates] = useState([])

    let chapiterState = []

    function mediasCp() {

        let etats = []



        chapitres.map((val, key) => {

            if (val.module === location.state.id) {

                sortedMedias.map((media) => {

                    pmc.map((p, k) => {

                        if (p.media === media.id && media.chapitre === val.id && p.apprenant === user.id) {

                            etats.push(p.etat)

                        }


                    })

                })

                if (etats.length > 0) {

                    if (etats.includes(false)) {

                        chapiterState.push(false);

                    }

                    else {

                        chapiterState.push(true);

                    }

                    etats = []

                }

            }

        })


    }
    const mediasOfC = mediasCp();// Very strange : I neevr use this variable , but when i remove it , it stuck displaying of incompleted chapters




    // console.log("the F chapitres globals  data", chapitres, location.state.id);


    // console.log("the vds", vds);


    // function idsVideosAPp(chap) {
    //     let videos = [];

    //     chapitres.map((val) => {
    //         if (val.module === location.state.id) {
    //             sortedMedias.map((media) => {
    //                 vds.map((v) => {
    //                     if (media.chapitre === val.id && media.type === "Video" && media.chapitre === chap) {
    //                         if (media.id === v.id) {
    //                             videos.push(v.id);
    //                         }
    //                     }
    //                 });
    //             });
    //         } else {
    //             alert('There is an error!!!');
    //         }
    //     });

    //     // Add an else condition for when no videos are found
    //     if (videos.length === 0) {
    // console.log('No videos found for the specified chapter.');
    //         return []; // Or return a specific message if preferred
    //     }

    //     return videos;
    // }

    // const ids_vid = idsVideosAPp(1074);//problem


    // console.log("The ids of videos", ids_vid);


    async function openModuleSubContent(ids, idmod, idchap, titre, titreFormation, idMedia, chapitreCount, idvideos, mediaIndex) {

        navigate('/tabbed', {

            state: {

                id: ids,

                idmodule: idmod,

                idchapitre: idchap,

                tm: titre,

                tf: titreFormation,

                idm: idMedia,

                chapcount: chapitreCount,
                idv: idvideos,
                mediaIndex: mediaIndex

            }

        })


        // alert("IDPLC", idvideos)
        // console.log("IDPLC", idvideos);



    }






    const [progres, setProgres] = useState([]);
    useEffect(() => {
        const fpd = async () => {
            if (!user.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tprogres/?user_id=${user.id}`);
                setProgres(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching progres data');
            }
        };

        fpd();
    }, [user.id]);


    // useEffect(() => {

    //     axiosClient.get('progres/')

    //         .then((res) => {

    //             setProgres(res.data)

    //         })

    // }, [])


    const progress = 100 / mediasOfChapitresOfModules

    const [completedMedia, setCompletedMedia] = useState(new Set());

    const checkPassed = (idMedia, idApp, url, isClickable) => {
        const handleLinkClick = async (val) => {
            // Check if media is already completed
            if (!completedMedia.has(idMedia)) {
                setCompletedMedia(prev => new Set(prev).add(idMedia)); // Update state immediately

                try {
                    // Update the progress status
                    await axiosClient.put(`progressmodcontent/${val.id}/`, {
                        etat: true,
                        media: idMedia,
                        apprenant: user.id,
                    });

                    const promises = [];
                    const formData = new FormData();

                    // Handle progress updates
                    formData.append('progres', progress);
                    formData.append('module', location.state.idmodule);
                    formData.append('apprenant', user.id);
                    promises.push(axiosClient.post('progres/', formData));

                    // Toast notification
                    toast({
                        title: 'Terminé!',
                        position: 'top-right',
                        isClosable: true,
                    });

                    // Update existing progress if needed
                    if (Array.isArray(progres)) {
                        progres.forEach((prog) => {
                            if (prog.module === location.state.idmodule && prog.apprenant === user.id) {
                                prog.progres += progress;
                                const progressFormData = new FormData();
                                progressFormData.append('progres', prog.progres);
                                promises.push(axiosClient.patch(`/progres/${prog.id}/`, progressFormData));
                            }
                        });
                    }

                    // Wait for all promises to complete
                    await Promise.all(promises);
                } catch (error) {
                    console.error("Error updating progress:", error);
                }
            }

            // Open the link in a new tab after processing
            window.open(url, '_blank');
            window.location.reload()
        };

        // Find the media progress
        const mediaProgress = pmc.find(val => val.apprenant === idApp && val.media === idMedia);
        if (mediaProgress) {
            const isCompleted = completedMedia.has(idMedia) || mediaProgress.etat;

            return (
                <Button
                    onClick={isClickable ? () => handleLinkClick(mediaProgress) : () => { }} // No-op function if not clickable
                    bg="#FFD24C"
                    color="#fff"
                    leftIcon={<AiOutlineLink fontSize={"25px"} />}
                    aria-label="Open link"
                    style={{
                        cursor: isClickable ? 'pointer' : 'not-allowed',  // Change cursor based on clickability
                        opacity: isClickable ? 1 : 0.5  // Adjust opacity for visibility
                    }}
                    disabled={!isClickable}  // Disable button if not clickable
                >
                    Ouvrir
                </Button>
            );
        }

        return null; // Return null if no mediaProgress found
    };


    const getRating = () => {

        let a

        listRatingwithIds.map((val, key) => {

            a = val.rating

        })

        return a

    }

    const rt = getRating()

    const [isOpen, setIsOpen] = useState(false);
    const [chapter, setChapter] = useState(0)


    const [videosIds, setVideoIds] = useState(0)

    const [chapterId, setChapterId] = useState(null); // State to store the selected chapter ID


    const corVideos = async (chapterId) => {
        try {
            const response = await axiosClient.get(`videoscorchapter/?chapitre_id=${chapterId}`);
            setVideoIds(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch video IDs');
        }
    };
    // console.log("vidddv", videosIds);


    useEffect(() => {
        if (chapterId) {
            corVideos(chapterId);
        }
    }, [chapterId]);


    // console.log("VideossxxxxIDS  ", videosIds);

    // useEffect(() => {
    //     // Fetch videos when 'chapter' changes
    //     const fetchVideos = async () => {
    //         if (chapter) {
    //             try {
    //                 const response = await axiosClient.get(`videoscorchapter/?chapitre_id=${chapter}`);
    //                 setVideoIds(response.data);
    //                 setError(null); // Reset error if the fetch is successful
    //             } catch (err) {
    //                 setError('Failed to fetch video IDs');
    //             }
    //         }
    //     };

    //     fetchVideos();
    // }, [chapter]);





    const OverlayOne = () => (

        <ModalOverlay

            bg='none'

            backdropFilter='auto'

            backdropBlur='4px'

        />

    )

    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const [isRatingVisible, setIsRatingVisible] = useState(true);


    const toast = useToast()

    const Rating = ({ defaultValue = 0, maxStars = 5, messages = [], isRatingVisible }) => {
        const [rating, setRating] = useState(defaultValue);
        const [hovered, setHovered] = useState(-1);
        const [isClicked, setIsClicked] = useState(false);

        const handleStarClick = (index) => {
            setRating(index + 1);
            setIsClicked(true);

            setIsRatingVisible(false);


            const hasUserRated = listRatingwithIds.some((rating) => rating.apprenant === user.id);

            if (messages[index]) {
                toast({
                    title: "Merci infiniment pour votre feedback ! Vos commentaires sont très précieux pour nous aider à améliorer notre service.",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right",
                });

                if (!hasUserRated) {
                    axiosClient
                        .post("rating/", {
                            apprenant: user.id,
                            module: location.state.id,
                            rating: index + 1,
                        })
                        .then(() => {
                            setListRatingwithIds([...listRatingwithIds, { apprenant: user.id }]);
                        });
                }

                listRatingwithIds.map((val, key) => {
                    const formData = new FormData();
                    formData.append("rating", index + 1);
                    axiosClient.patch(`/rating/${val.id}/`, formData);
                });
            }
        };

        const handleStarHover = (index) => {
            setHovered(index);
        };

        const renderStars = () => {
            const stars = [];

            for (let i = 0; i < maxStars; i++) {
                stars.push(
                    <Box
                        key={i}
                        onClick={() => handleStarClick(i)}
                        onMouseEnter={() => handleStarHover(i)}
                        onMouseLeave={() => handleStarHover(-1)}
                    >
                        <StarIcon
                            color={i <= (hovered - 1) || i <= (rating - 1) ? "yellow.400" : "gray.200"}
                            boxSize={9}
                            p={1}
                        />
                    </Box>
                );
            }

            return <Flex direction="row">{stars}</Flex>;
        };

        return (
            <>
                {isRatingVisible && (!isClicked || rating !== defaultValue) && (
                    <Box>
                        {renderStars()}
                    </Box>
                )}
            </>
        );
    };

    const [isInputFocused, setIsInputFocused] = useState(false);


    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
    };


    // console.log("progres XXLTR", progres);


    function getProgressOfModule() {

        let p

        progres.map((prog, k) => {

            if (prog.module === location.state.id && prog.apprenant === user.id)

                p = prog.progres

        })

        return p

    }





    const ppx = getProgressOfModule()


    // console.log("ATTTTATATATA", tests);


    const [hasShownModal, setHasShownModal] = useState(false);

    useEffect(() => {
        // Check cookies for the modal status
        const modalStatus = Cookies.get('hasShownModal');
        if (modalStatus === 'true') {
            setHasShownModal(true);
        }
    }, []);

    useEffect(() => {
        const allTestsValidated = tests && Array.isArray(tests) && tests.every((test, index) => isvalidate && isvalidate[index]);
        const allConditionsMet = ppx === 100 &&
            (listefeeds ? listefeeds.length === 0 : true) &&
            (listerates ? listerates.length === 0 : true) &&
            allTestsValidated;

        if (allConditionsMet && !hasShownModal) {
            const timeoutId = setTimeout(() => {
                setIsOpen(true);
                setHasShownModal(true);
                Cookies.set('hasShownModal', 'true', { expires: 365 }); // Set cookie for 1 year
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
    }, [ppx, listefeeds, listerates, tests, isvalidate, hasShownModal]);

    const [message, setMessage] = useState('')

    const inp_ = useColorModeValue('white', 'gray.800')

    const progressLabel = isNaN(finalProgres) ? "0%" : `${Math.round(finalProgres)}%`;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFeedbackModified, setIsFeedbackModified] = useState(false);


    const sendFeedback = () => {
        // Check if the required fields are filled
        if (!message || message.trim() === '') {
            toast({
                title: "Le message ne peut pas être vide.",
                status: "error",
                position: "bottom-right",
                isClosable: true,
            });
            return; // Prevent further execution
        }

        const formData = new FormData();
        formData.append('id_module', location.state.id);
        formData.append('message', message);
        formData.append('apprenant', user.id);

        axiosClient.post('/feedback/', formData)
            .then(() => {
                toast({
                    title: "Envoyé avec succès !",
                    position: "bottom-right",
                    isClosable: true,
                });
                setIsSubmitted(true);
            })
            .catch((error) => {
                console.error("Error sending feedback:", error);
                toast({
                    title: "Erreur lors de l'envoi du feedback.",
                    status: "error",
                    position: "bottom-right",
                    isClosable: true,
                });
            });
    };




    // console.log("cvvcvcvvcvcvcv", chapitres);



    // console.log(" location state", location.state.titre);


    let counter = 1


    // console.log("sortedMediasPPAPPAPAPPAPA", sortedMedias);

    // console.log("user.iduser.iduser.id", user.id);



    const uniqueMcModules = mcModules.filter((value, index, self) =>
        index === self.findIndex((item) =>
            item.id === value.id &&
            item.chapitre_name === value.chapitre_name &&
            item.chapitre_created_at === value.chapitre_created_at
        )
    );
    // console.log("uniqueMcModulesuniqueMcModules", uniqueMcModules);


    // console.log("finalProgres", finalProgres);

    // console.log("idsVideosAPp(val.id)", idsVideosAPp(1028))

    return (





        loading ? (

            <Flex justifyContent="center" alignItems="center" height="83vh" >
                <Spinner size="xl" />
            </Flex>
        ) :


            (
                <>



                    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>

                        {overlay}

                        <ModalContent style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}>

                            <ModalBody rounded='md'  >

                                <Lottie width={'75%'} options={defaultOption} />

                            </ModalBody>

                            <ModalBody h={'55%'}>

                                <Text color={'blue.600'} fontSize={'xl'} align={"center"} fontWeight={"bold"} fontFamily={"revert-layer"}>

                                    Votre avis nous intéresse !

                                </Text>




                                {!isSubmitted ? (
                                    <Text fontWeight={'medium'} fontFamily={"cursive"}>
                                        Si vous avez un moment, pourriez-vous nous donner une note en utilisant les étoiles ci-dessous ? Votre feedback nous aide à améliorer notre service pour vous et pour les autres clients. Merci d'avance pour votre contribution.
                                    </Text>
                                ) : (
                                    <Text fontWeight={'medium'} fontFamily={"cursive"}>
                                        Merci d'avoir envoyé votre feedback ! Votre contribution est précieuse pour nous.
                                    </Text>
                                )}


                                <Flex justify={'center'} mt={2}>

                                    <Rating isRatingVisible={isRatingVisible}
                                        defaultValue={3} maxStars={5} messages={[1, "2", "3", "4", "5"]} />

                                </Flex>


                                {!isSubmitted && (<Flex mt="8">

                                    <Input name='message' bg={inp_} p={4} mr={2} width="90%" onChange={e => { setMessage(e.target.value); setIsFeedbackModified(true); }
                                    } placeholder="Saisir votre commentaire" ></Input>

                                    <Button

                                        width={{ base: "100%", md: "auto" }}

                                        colorScheme='yellow'

                                        onClick={() => sendFeedback()}

                                        px={{ base: "4", md: "6" }}

                                        fontSize={{ base: "sm", md: "md" }}

                                    >

                                        <Flex alignItems="center">

                                            <Icon as={FaPaperPlane} mr="2" />

                                            Envoyer

                                        </Flex>

                                    </Button>

                                </Flex>)}



                            </ModalBody>

                            <ModalFooter>

                                <Button width={{ base: "100%", md: "110px" }} colorScheme="blue" onClick={() => setIsOpen(false)}>

                                    Fermer

                                </Button>

                            </ModalFooter>

                        </ModalContent>

                    </Modal >


                    <Box>

                        <Flex justifyContent={"space-between"} mt={2}>

                            <Box display="flex" justifyContent="flex-start">

                                <Flex alignItems="center">

                                    <Lottie height={60} width={60} options={defaultOpt} />

                                    <GradientText gradient="#FFD140, #089bd7">Module {location.state.titre}</GradientText>

                                </Flex>

                            </Box>

                            {/* <. Box>



                                    <CircularProgress isIndeterminate size="60px" color="green.400" />

</. Box> */}

                            <Box>


                                {progressLoad ? (
                                    <CircularProgress isIndeterminate size="60px" color="green.400" />
                                ) : (
                                    finalProgres !== null ? (
                                        <CircularProgress size="60px" value={finalProgres} color="green.400">
                                            <CircularProgressLabel>{`${progressLabel}`}</CircularProgressLabel>
                                        </CircularProgress>
                                    ) : (
                                        <CircularProgress isIndeterminate size="60px" color="green.400" />

                                    )
                                )}


                            </Box>

                        </Flex>

                        <Accordion mt="50px" allowToggle maxW="100%" mx="auto">

                            {chapitres.map((val, ckey) => {
                                let chapitreNumber = ckey + 1


                                // setChapter(val.id)
                                //problem is in ckey wihen i  switch to other module it start with order of chapitre ckey = 0

                                if (true) {

                                    return (

                                        <AccordionItem key={val.id}>

                                            <h2>

                                                <AccordionButton

                                                    bg={bg}

                                                    color="#fff"

                                                    borderRadius="md"

                                                    h="70px"

                                                    mb={2}
                                                    onClick={() => setChapterId(val.id)}

                                                    _hover={{ bg: 'gray.300' }}

                                                    _focus={{ boxShadow: 'none' }}

                                                    _active={{ bg: 'gray.400' }}

                                                >

                                                    <Box flex="1" textAlign="left" fontWeight="semibold">

                                                        Chapitre {chapitreNumber}:  {val.name}

                                                    </Box>

                                                    <AccordionIcon />

                                                </AccordionButton>

                                            </h2>

                                            <AccordionPanel

                                                borderTopWidth="1px"

                                                borderTopColor="gray.200"

                                                borderBottomWidth="1px"

                                                borderBottomColor="gray.200"

                                                pb={4}

                                            >

                                                <Accordion allowToggle>

                                                    {uniqueMcModules.map((media) => {
                                                        // console.log("Media Object:", media);
                                                        // Check if the media's chapitre matches val.id
                                                        if (media.chapitre === val.id) {
                                                            const isClickable = media.activated; // Determine if the item is clickable based on media.activated



                                                            // console.log("isClickable", isClickable);

                                                            return (
                                                                (media.type === "PDF" ||
                                                                    media.type === "PPT" ||


                                                                    media.type === "Video") ? (
                                                                    <Stack
                                                                        key={media.id}
                                                                        h={{ base: "90px", md: "60px" }}
                                                                        w={{ base: "100%", md: "100%" }}
                                                                        borderRadius="lg"
                                                                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                                                        border="1px solid rgba(0, 0, 0, 0.25)"
                                                                        p={4}
                                                                        mb={3}
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        bg="gray.100"
                                                                        opacity={isClickable ? 1 : 0.5} // Adjust opacity based on clickability
                                                                        cursor={isClickable ? 'pointer' : 'not-allowed'} // Change cursor based on clickability
                                                                        onClick={isClickable ? async () => {
                                                                            // Handle the click event here
                                                                            if (media.type === "Video") {
                                                                                // A
                                                                                const videoIds = await corVideos(val.id);

                                                                                // A wait the result of corVideos

                                                                                // console.log("VCIPO", videosIds);

                                                                                if (!videosIds || videosIds.length === 0) {
                                                                                    console.warn("Videos IDs are not available yet. Blocking access.");
                                                                                    return; // Block access if videosIds are not available
                                                                                }
                                                                                openModuleSubContent(
                                                                                    location.state.idf,
                                                                                    location.state.id,
                                                                                    val.id,
                                                                                    location.state.titreF,
                                                                                    location.state.titre,
                                                                                    media.id,
                                                                                    mcModules,
                                                                                    videosIds,// Pass the resolved videos
                                                                                    media.order_index
                                                                                );
                                                                            } else if (media.type === "PDF" || media.type === "PPT") {
                                                                                if (!videosIds || videosIds.length === 0) {
                                                                                    console.warn("Videos IDs are not available yet. Blocking ssss.");
                                                                                    return; // Block access if videosIds are not available
                                                                                }
                                                                                openModuleSubContent(
                                                                                    location.state.idf,
                                                                                    location.state.id,
                                                                                    val.id,
                                                                                    location.state.titreF,
                                                                                    location.state.titre,
                                                                                    media.id,
                                                                                    mcModules,
                                                                                    videosIds,// Pass the resolved videos
                                                                                    media.order_index
                                                                                );
                                                                            } else if (media.type === "Link") {
                                                                                // console.log("media  type", media);

                                                                            }
                                                                            else {
                                                                                if (!videosIds || videosIds.length === 0) {
                                                                                    console.warn("Videos IDs are not available yet. Blocking access.");
                                                                                    return; // Block access if videosIds are not available
                                                                                }
                                                                                openModuleSubContent(
                                                                                    location.state.idf,
                                                                                    location.state.id,
                                                                                    val.id,
                                                                                    location.state.titreF,
                                                                                    location.state.titre,
                                                                                    media.id,
                                                                                    mcModules,
                                                                                    videosIds,// Pass the resolved videos
                                                                                    media.order_index
                                                                                );
                                                                            }
                                                                        } : undefined} // Handle click only if clickable
                                                                    >
                                                                        <Icon as={FaCheckCircle} color={media.etat === true ? ic_ : "grey"} h="32px" w="32px" mr={{ base: "20px", md: "60px" }} />
                                                                        <Icon as={media.type === 'Video' ? MdOndemandVideo : media.type === 'Link' ? AiOutlineLink : media.type === 'PPT' ? RiSlideshowFill : AiOutlineFileZip} color={ic} h="32px" w="32px" />
                                                                        <Box flexGrow={1} display="flex">
                                                                            <Box ml={{ base: "10px", md: "30px" }}>
                                                                                {media.name} {/* Placeholder text, replace with actual content */}
                                                                            </Box>
                                                                        </Box>
                                                                        <Button
                                                                            w="110px"
                                                                            onClick={async (e) => {
                                                                                e.stopPropagation(); // Prevent event bubbling
                                                                                if (isClickable) {
                                                                                    if (media.type === "Video") {

                                                                                        const videoIds = await corVideos(val.id);

                                                                                        // A wait the result of corVideos

                                                                                        // console.log("VCIPO", videosIds);

                                                                                        if (!videosIds || videosIds.length === 0) {
                                                                                            console.warn("Videos IDs are not available yet. Blocking access.");
                                                                                            return; // Block access if videosIds are not available
                                                                                        }
                                                                                        openModuleSubContent(
                                                                                            location.state.idf,
                                                                                            location.state.id,
                                                                                            val.id,
                                                                                            location.state.titreF,
                                                                                            location.state.titre,
                                                                                            media.id,
                                                                                            mcModules,
                                                                                            videosIds,// Pass the resolved videos
                                                                                            media.order_index);

                                                                                    } else if (media.type === "PDF" || media.type === "PPT") {
                                                                                        // alert("pdf zone")
                                                                                        // if (!videosIds || videosIds.length === 0) {
                                                                                        //     console.warn("Videos IDs are not available yet. Blocking access.");
                                                                                        //     return; // Block access if videosIds are not available
                                                                                        // }
                                                                                        openModuleSubContent(
                                                                                            location.state.idf,
                                                                                            location.state.id,
                                                                                            val.id,
                                                                                            location.state.titreF,
                                                                                            location.state.titre,
                                                                                            media.id,
                                                                                            mcModules,
                                                                                            videosIds,// Pass the resolved videos
                                                                                            media.order_index);
                                                                                    } else {
                                                                                        if (!videosIds || videosIds.length === 0) {
                                                                                            console.warn("Videos IDs are not available yet. Blocking access.");
                                                                                            return; // Block access if videosIds are not available
                                                                                        }
                                                                                        openModuleSubContent(
                                                                                            location.state.idf,
                                                                                            location.state.id,
                                                                                            val.id,
                                                                                            location.state.titreF,
                                                                                            location.state.titre,
                                                                                            media.id,
                                                                                            mcModules,
                                                                                            videosIds,// Pass the resolved videos
                                                                                            media.order_index
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }}
                                                                            color="#fff"
                                                                            bg="#089bd7"
                                                                            isDisabled={!isClickable} // Disable the button when not clickable
                                                                        >
                                                                            Access
                                                                        </Button>
                                                                    </Stack>
                                                                ) : (<Stack
                                                                    key={media.id}
                                                                    h={{ base: "90px", md: "60px" }}
                                                                    w={{ base: "100%", md: "100%" }}
                                                                    borderRadius="lg"
                                                                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                                                    border="1px solid rgba(0, 0, 0, 0.25)"
                                                                    p={4}
                                                                    mb={3}
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    bg="gray.100"
                                                                    opacity={isClickable ? 1 : 0.5} // Adjust opacity based on clickability
                                                                    cursor={isClickable ? 'pointer' : 'not-allowed'} // Change cursor based on clickability
                                                                // Handle click only if clickable
                                                                >
                                                                    <Icon as={FaCheckCircle} color={media.etat === true ? ic_ : "grey"} h="32px" w="32px" mr={{ base: "20px", md: "60px" }} />
                                                                    <Icon as={media.type === 'Video' ? MdOndemandVideo : media.type === 'Link' ? AiOutlineLink : media.type === 'PPT' ? RiSlideshowFill : AiOutlineFileZip} color={ic} h="32px" w="32px" />
                                                                    <Box flexGrow={1} display="flex">
                                                                        <Box ml={{ base: "10px", md: "30px" }}>
                                                                            {media.name} {/* Placeholder text, replace with actual content */}
                                                                        </Box>
                                                                    </Box>
                                                                    {checkPassed(media.id, user.id, media.link, isClickable)}

                                                                </Stack>)
                                                            );
                                                        }

                                                        return null; // Return null if the conditions are not met
                                                    })}





                                                </Accordion>

                                            </AccordionPanel>

                                        </AccordionItem>

                                    )

                                } else {

                                    return (

                                        <AccordionItem key={val.id}>

                                            <h2>

                                                <AccordionButton

                                                    bg={bg}

                                                    color="#fff"

                                                    borderRadius="md"

                                                    h="70px"

                                                    mb={2}

                                                    _hover={{ bg: 'gray.300' }}

                                                    _focus={{ boxShadow: 'none' }}

                                                    _active={{ bg: 'gray.400' }}

                                                >

                                                    <Box flex="1" textAlign="left" fontWeight="semibold">

                                                        Chapitre {chapitreNumber} :  {val.name}

                                                    </Box>

                                                    <AccordionIcon />

                                                </AccordionButton>

                                            </h2>

                                            <AccordionPanel

                                                borderTopWidth="1px"

                                                borderTopColor="gray.200"

                                                borderBottomWidth="1px"

                                                borderBottomColor="gray.200"

                                                pb={4}

                                            >

                                                <Accordion allowToggle>

                                                    {sortedMedias.map((media) => {

                                                        return pmc.map((p, key) => {

                                                            if (media.id === p.media && p.apprenant === user.id) {

                                                                const smallestItem = chapitres.reduce((acc, curr) => {

                                                                    if (curr.id < acc.id || acc.id === undefined) {

                                                                        return curr;

                                                                    }

                                                                    return acc;

                                                                }, {});

                                                                return (

                                                                    media.chapitre === val.id && (

                                                                        (media.type === "PDF" || media.type === "PPT" || media.type === "Video") ? (


                                                                            <Stack key={media.id} h={{ base: "90px", md: "60px" }} w={{ base: "100%", md: "100%" }} borderRadius="lg" boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="1px solid rgba(0, 0, 0, 0.25)" p={4} mb={3} direction="row" alignItems="center" bg="gray.100"

                                                                                opacity={0.5}

                                                                                cursor="not-allowed">

                                                                                <Icon as={FaCheckCircle} color={p.etat === true ? ic_ : "grey"} h="32px" w="32px" mr={{ base: "20px", md: "60px" }} />

                                                                                <Icon as={media.type === 'Video' ? MdOndemandVideo : media.type === 'Link' ? AiOutlineLink : media.type === 'PPT' ? RiSlideshowFill : AiOutlineFileZip} color={ic} h="32px" w="32px" />

                                                                                <Box flexGrow={1} display="flex" >

                                                                                    <Box ml={{ base: "10px", md: "30px" }}>

                                                                                        {media.name}

                                                                                    </Box>

                                                                                </Box>

                                                                                <Button isDisabled w="80px" onClick={() => media.type === "Video" ? openModuleSubContent(location.state.idf, location.state.id, val.id, location.state.titreF, location.state.titre, media.id, mediasOfChapitresOfModules) : media.type === "PDF" ? openModuleSubContentDoc(location.state.idf, location.state.id, val.id, location.state.titreF, location.state.titre, media.id, mediasOfChapitresOfModules) : openModuleSubContentPPt(location.state.idf, location.state.id, val.id, location.state.titreF, location.state.titre, media.id, mediasOfChapitresOfModules)} color="#fff" bg="#089bd7">Access</Button>

                                                                            </Stack>

                                                                        ) : null

                                                                    )

                                                                )

                                                            }

                                                        })

                                                    })}

                                                    {link.map((l, key) => {

                                                        if (l.chapitre === val.id) {


                                                            return (

                                                                <Stack key={key} h={{ base: "90px", md: "60px" }} w={{ base: "100%", md: "100%" }} borderRadius="lg" boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="1px solid rgba(0, 0, 0, 0.25)" p={4} mb={3} direction="row" alignItems="center" bg="gray.100"

                                                                    opacity={0.5}

                                                                    cursor="not-allowed">




                                                                    {pmc.map((p, key) => {

                                                                        if (l.id === p.media && p.apprenant === user.id)

                                                                            return (

                                                                                <>

                                                                                    <Icon as={FaCheckCircle} color={p.etat === true ? ic_ : "grey"} h="32px" w="32px" mr={{ base: "20px", md: "60px" }} />

                                                                                    <Icon as={AiOutlineLink} color={ic} h="32px" w="32px" />

                                                                                    <Box flexGrow={1} display="flex" >

                                                                                        <Box ml={{ base: "10px", md: "30px" }}>

                                                                                            <a style={{ cursor: 'pointer' }}>{l.link.substring(0, 20)}</a>

                                                                                        </Box>

                                                                                    </Box>


                                                                                    {!p.etat ? checkPassed(l.id, user.id, l.link)

                                                                                        : <LinkButtondb >

                                                                                            Access

                                                                                        </LinkButtondb>}



                                                                                </>)

                                                                    })}



                                                                </Stack>

                                                            )

                                                        }

                                                    })}



                                                </Accordion>

                                            </AccordionPanel>

                                        </AccordionItem>

                                    )


                                }

                            })}




                            {chapitres.next !== undefined && (
                                <Box mb={3} display="flex" justifyContent="center" mt={4}>

                                    <Button
                                        onClick={() => handlePageChange(currentCPage - 1)}
                                        isDisabled={currentCPage === 1}
                                        colorScheme="yellow"
                                        variant="outline"
                                        mr={2}
                                        leftIcon={<span>&lt;</span>} // Left Arrow
                                    >
                                        Précédent
                                    </Button>

                                    <Text mt={2} mx={2} fontSize="lg" fontWeight="bold" color="yellow.500">
                                        {`Page ${currentCPage} of ${totalPages}`}
                                    </Text>

                                    <Button
                                        onClick={() => handlePageChange(currentCPage + 1)}
                                        isDisabled={currentCPage === totalPages} // Disable if on last page
                                        colorScheme="yellow"
                                        variant="outline"
                                        ml={2}
                                        rightIcon={<span>&gt;</span>} // Right Arrow
                                    >
                                        Suivant
                                    </Button>
                                </Box>
                            )}
                            <AccordionItem >

                                <h2>

                                    <AccordionButton

                                        bg={bg}

                                        color="#fff"

                                        borderRadius="md"

                                        h="70px"

                                        mb={2}

                                        _hover={{ bg: 'gray.300' }}

                                        _focus={{ boxShadow: 'none' }}

                                        _active={{ bg: 'gray.400' }}

                                    >

                                        <Box flex="1" textAlign="left" fontWeight="semibold">

                                            Test

                                        </Box>

                                        <AccordionIcon />

                                    </AccordionButton>

                                </h2>

                                <AccordionPanel

                                    borderTopWidth="1px"

                                    borderTopColor="gray.200"

                                    borderBottomWidth="1px"

                                    borderBottomColor="gray.200"

                                    pb={4}

                                >

                                    <Accordion allowToggle>

                                        {testActivated}


                                    </Accordion>

                                </AccordionPanel>

                            </AccordionItem>


                        </Accordion>



                    </Box>

                </>

            )


    )

}

