import {
    Badge,
    Box,
    Button, ButtonGroup, Card, CardBody, CardFooter,
    Circle,
    Flex, Heading, Image,
    Input,
    InputGroup,
    InputLeftElement,
    Progress,
    Spacer,
    Spinner,
    Stack, Text,
    useColorModeValue
} from '@chakra-ui/react'

import {
    SimpleGrid,
    useDisclosure
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { BsBookFill } from 'react-icons/bs'
import {
    FiHome,
} from 'react-icons/fi'
import { ImCancelCircle } from 'react-icons/im'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosClient from '../../axios-client'
import DemandeAcces from './DemandeAcces'
import Progres from './Progres'

import bookData from '../../assets/lot/book.json'
import animationData from '../../assets/lot/learning.json'

import { SearchIcon, WarningIcon } from '@chakra-ui/icons'
import Lottie from 'react-lottie'
import { useStateContext } from '../../context/ContextProvider'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const defaultOpt = {
    loop: true,
    autoplay: true,
    animationData: bookData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}
function Modules() {

    const [searchQuery, setSearchQuery] = useState('');


    const [listModule, setListModule] = useState([])
    const [listAcces, setListAcces] = useState([])
    const [listApprenant, setListApprenant] = useState([])
    const [listProgres, setListProgres] = useState([])
    const [listChapitres, setListChapitres] = useState([])

    const [idApprenant, setIdApprenant] = useState('')
    const location = useLocation()
    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate();



    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true); // Add isLoading state



    // const fetchData = useCallback(async () => {
    //     try {
    //         setLoading(true);
    //         const progressRes = await axiosClient.get('progres/');
    //         const accessRes = await axiosClient.get('acces/');
    //         setListProgres(progressRes.data);
    //         setListAcces(accessRes.data);
    //         setLoading(false);
    //     } catch (error) {
    // console.log(error);
    //         setLoading(false);
    //     }
    // }, []);


    useEffect(() => {
        axiosClient.get('chapitre/')
            .then((res) => setListChapitres(res.data))
    }, [])


    const { user, setUser } = useStateContext();

    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])
    useEffect(() => {
        const fpd = async () => {
            if (!user.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tprogres/?user_id=${user.id}`);
                setListProgres(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching progres data');
            }
        };

        fpd();
    }, [user.id]);
    const [requestActivationOngoing, setRequestActivationOngoing] = useState([]);

    // console.log("The LP", listProgres);
    const fetchAccessData = async () => {
        if (!user?.id) {
            return; // Exit early if user.id doesn't exist
        }

        try {
            const response = await axiosClient.get(`tacces/?user_id=${user.id}`);
            const accessData = response.data;

            // Handle access statuses
            const grantedAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                access.etat === true &&
                access.encours === false &&
                access.refus === false
            );

            const ongoingAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                access.encours
            );

            const reactivationAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                !access.encours &&
                access.refus === true
            );

            const prohibitedAccessModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                !access.encours &&
                access.refus === true
            );

            const ongoingReactivationModules = accessData.filter(access =>
                access.apprenant === user.id &&
                !access.etat &&
                access.encours
            );

            // Update state
            setRequestActivationOngoing(ongoingReactivationModules);
            setGrantedAccess(grantedAccessModules);
            setOngoingAccess(ongoingAccessModules);
            setReactivationAccess(reactivationAccessModules);
            setProhibitedAccess(prohibitedAccessModules);
        } catch (error) {
            // console.error('Error fetching access data', error);
        }
    }
    useEffect(() => {
        fetchAccessData(); // Initial fetch

        const intervalId = setInterval(fetchAccessData, 30000); // Poll every 30 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [user]);

    // useEffect(() => {
    //     let timer = null;
    //     if (!loading) {
    //         timer = setTimeout(() => {
    //             fetchData();
    //         }, 300); // Delay API call by 500ms
    //     }
    //     return () => clearTimeout(timer);
    // }, [fetchData, loading]);



    // console.log("location.state.idlocation.state.id 1111", location.state.id);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);






    useEffect(() => {
        axiosClient.get(`filtered-modules/?search_query=${searchQuery}&page=${currentPage}&formation_id=${location.state.id}`)
            .then((res) => {
                setListModule(res.data.results)
                setTotalPages(res.data.total_pages);

                setIsLoading(false); // Set isLoading to false when data is fetched

            })
            .catch((error) => {
                setIsLoading(false); // Set isLoading to false even if there's an error
            });
    }, [searchQuery, currentPage])

    // console.log("total_pages", totalPages);

    useEffect(() => {
        const fetchAccessData = async () => {
            if (!user.id) {
                return; // Exit early if user.id doesn't exist
            }

            try {
                const response = await axiosClient.get(`tacces/?user_id=${user.id}`);
                setListAcces(response.data);
            } catch {
                // Optionally, you can log the error to the console if needed
                // console.log('Error fetching access data');
            }
        };

        fetchAccessData();
    }, [user.id]);



    // console.log("modules  list , ", listModule);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    // console.log("listModule", listModule);



    useEffect(() => {
        axiosClient.get('apprenants/')
            .then((res) => setListApprenant(res.data))
    }, [])





    // console.log("listAccesPAP", listAcces);



    const getAccesbyApprenant = (idA, idM) => {
        let a = "error"
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                if ((val.etat === false && val.encours === true && val.refus === false)) {
                    a = "en cours"
                }
                if (val.etat === false && val.encours === false && val.refus === true) {
                    a = "reactiver"
                }
                if (val.etat === true && val.encours === false && val.refus === false) {
                    a = "commencer"
                }
                if ((val.etat === false && val.encours === true && val.refus === true)) {
                    a = "en cours d'activation"
                }
            }
        })
        return a
    }

    const [idA, setIdA] = useState(null)

    useEffect(() => {
        if (user && user.id) {
            setIdA(user.id)
        }
    }, [user])



    // const filteredModules = listModule.filter((val) => {

    //     const ChapitresOfModules = listChapitres.filter((chapitre) => chapitre.module === val.id);

    //     return ChapitresOfModules.length > 0 && val.titre.toLowerCase().includes(searchQuery.toLowerCase());


    // });





    function GradientText({ children, gradient }) {
        const gradientBg = {
            background: `linear-gradient(to left, ${gradient})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        };

        return (
            <Text ml={0.5} mt={2} fontWeight={"bold"} fontSize={"28px"} as="span" sx={gradientBg}>
                {children}
            </Text>
        );
    }
    const inputBg = useColorModeValue("white", "gray.700");
    function truncateDescription(description) {
        const words = description.split(' ');
        const truncatedWords = words.slice(0, 30);
        return truncatedWords.join(' ') + (words.length > 15 ? '...' : '');
    }


    const noModulesFound = searchQuery.trim() !== "" && listModule.length === 0;




    const [loadingId, setLoadingId] = useState(null); // Track which request is loading
    const [ongoingAccess, setOngoingAccess] = useState([]); // Track granted access modules
    const [reactivationAccess, setReactivationAccess] = useState([]); // Track granted access modules
    const [grantedAccess, setGrantedAccess] = useState([]); // Track granted access modules
    const [prohibitedAcces, setProhibitedAccess] = useState([]); // Track granted access modules
    // Function to create a new access request
    const postAccessRequest = async (moduleId, apprenantId) => {
        try {
            setLoadingId(moduleId); // Set loading state for this module
            const response = await axiosClient.post('accescases/', {
                idModule: moduleId,
                idApprenant: apprenantId,
                typeAccess: 'accessRequest'
            });
            // console.log("Access request created successfully:", response.data);
            setGrantedAccess(prev => [...prev, moduleId]); // Add moduleId to granted access list
        } catch (error) {
            // console.error("Error creating access request:", error);
            // Handle error feedback here
        } finally {
            setLoadingId(null); // Reset loading state
        }
    };

    // Function to update an existing access request
    const updateAccessRequest = async (moduleId, apprenantId) => {
        try {
            setLoadingId(moduleId); // Set loading state for this module
            const response = await axiosClient.put('accescases/', {
                idModule: moduleId,
                idApprenant: apprenantId,
                typeAccess: 'activationRequest'
            });

            window.location.reload(); // Refresh the page
            // console.log("Access request updated successfully:", response.data);
            // Handle any updates to your state here
        } catch (error) {
            // console.error("Error updating access request:", error);
            // Handle error feedback here
        } finally {
            setLoadingId(null); // Reset loading state
        }
    };
    const handleProhibitedAccess = async (moduleId, apprenantId) => {
        try {
            setLoadingId(moduleId); // Set loading state for this module
            const response = await axiosClient.post('accescases/', {
                idModule: moduleId,
                idApprenant: apprenantId,
                typeAccess: 'activationRequest' // Change typeAccess to 'activationRequest'
            });
            // console.log("Activation request created successfully:", response.data);
            // Handle state updates if needed, like showing a success message or updating access states
        } catch (error) {
            // console.error("Error creating activation request:", error);
            // Handle error feedback here
        } finally {
            setLoadingId(null); // Reset loading state
        }
    };
    const getProgresbyApprenant = (idA, idM) => {
        let e = "error"
        let a
        listProgres.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {

                e = val.progres
                a = e.toFixed(0)
            }
        })

        return a
    }


    const reactiver = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.patch(`/acces/${id}/`, {
            encours: true,
        })
            .then((res) => {

            })
            .catch((error) => {
            })
    }

    const annulerDamandeAcces = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.delete(`/acces/${id}/`)
            .then((res) => {


            })
            .catch((error) => {
            })
    }

    const annulerReactivation = (idA, idM) => {
        let id
        listAcces.map((val, key) => {
            if (val.apprenant === idA && val.module === idM) {
                return id = val.id
            }
        })
        axiosClient.patch(`/acces/${id}/`, {
            etat: false,
            encours: false,
            refus: true
        })
            .then((res) => {

            })
            .catch((error) => {
            })
    }



    function openModContent(ids, titre, titreFormation, desc, formation, progress) {
        navigate('/chapitres', {
            state: {
                id: ids,
                titre: titre,
                titreF: titreFormation,
                description: desc,
                idf: formation,
                prog: progress
            }
        })
    }


    // console.log("grantedAccess", grantedAccess);

    // useEffect(() => {
    //     // Check if there are any ongoing reactivation requests
    //     const hasOngoingReactivation = requestActivationOngoing.length > 0;

    //     if (hasOngoingReactivation) {
    //         alert("Il y a des demandes de réactivation en cours pour certains modules !");
    //     }
    // }, [requestActivationOngoing]);
    const setState = {}

    // idA

    // console.log("The PPr Id ,", idA);
    // console.log("LACSSS,", listAcces);

    return (
        <Box>

            <Box display="flex" justifyContent="space-between">
                <Box display="flex" justifyContent="flex-start">

                    <Flex alignItems="center">
                        <Lottie height={80} width={80} options={defaultOpt} />
                        <GradientText gradient="#ffd140, #089bd7">
                            Formation {location.state.titre}</GradientText>                    </Flex>
                </Box>
                <Spacer flex="1" />
                <Box>
                    <InputGroup width="100%" size="md" borderRadius="md">
                        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                        <Input
                            value={searchQuery}
                            bg={inputBg}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            type="text"
                            placeholder="Recherche Modules"
                            borderRadius="md"
                            py={3}
                            pl={10}
                            boxShadow="md"
                            _focus={{ outline: "none", boxShadow: "outline" }}
                        /></InputGroup>
                </Box>
            </Box>






            {isLoading ? (

                <Flex justifyContent="center" alignItems="center" height="83vh">
                    <Spinner size="xl" />
                </Flex>
            ) : noModulesFound ? (
                // Show the message only when the search query is provided and no formations are found
                <>
                    <Lottie height={400} width={550} options={defaultOptions} />
                    <Text fontSize={"2xl"} color={"#1A365D"} fontFamily={"mono"} textAlign="center" alignItems="center" justifyContent="center">Aucune formation n'a été trouvée !</Text>
                </>
            ) : (
                // Show the filtered formations when available
                <Box>
                    <Box height={"100%"} overflowY="auto" css={{
                        "&::-webkit-scrollbar": {
                            width: "9px",
                            height: "6px",

                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#089bd7",
                            borderRadius: "6px",

                        },
                        "&::-webkit-scrollbar-track": {
                            background: "gray.50",
                        },
                    }} >

                        <SimpleGrid mt={37} columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 3 }}>
                            {listModule.map((val, key) => {
                                const modLoad = loadingId === val.id || grantedAccess.includes(val.id); // Check if loading or granted access

                                // console.log(" getProgresbyApprenant(idA, val.id)", getProgresbyApprenant(idA, val.id));

                                return (

                                    <Card key={val.id} mx={5} >
                                        <CardBody >
                                            <Image

                                                src={val.image}
                                                height="180px"
                                                width="100%"
                                                alt='Green double couch with wooden legs'
                                                borderRadius='lg'
                                            />
                                            {listAcces.map((acces, key) => {
                                                if (val.id === acces.module && getAccesbyApprenant(idA, val.id) === "commencer") {

                                                    if (getProgresbyApprenant(idA, val.id) === '100') {
                                                        return (

                                                            <React.Fragment key={acces.id}>
                                                                <Badge rounded="full" position="absolute"
                                                                    top={6}
                                                                    left={6} fontSize="0.8em" colorScheme="green">
                                                                    Terminé
                                                                </Badge>
                                                                <Circle
                                                                    size="10px"
                                                                    position="absolute"
                                                                    top={6}
                                                                    right={6}
                                                                    bg="green.200"
                                                                />
                                                            </React.Fragment>


                                                        )
                                                    } else {
                                                        return (

                                                            <React.Fragment key={acces.id}>
                                                                <Badge rounded="full" position="absolute"
                                                                    top={6}
                                                                    left={6} fontSize="0.8em" colorScheme="green">
                                                                    En cours
                                                                </Badge>
                                                                <Circle
                                                                    size="10px"
                                                                    position="absolute"
                                                                    top={6}
                                                                    right={6}
                                                                    bg="green.200"
                                                                />
                                                            </React.Fragment>


                                                        )
                                                    }
                                                    // return (
                                                    //     <>
                                                    //         {getProgresbyApprenant(idA, val.id) }
                                                    //     </>
                                                    // );

                                                } else {

                                                }
                                            })
                                            }
                                            <Stack mt='3' spacing='3'>
                                                {
                                                    getProgresbyApprenant(idA, val.id) === "error"
                                                        ? <Progress size='md' isIndeterminate />
                                                        : getAccesbyApprenant(idA, val.id) === "reactiver" || getAccesbyApprenant(idA, val.id) === "en cours d'activation"
                                                            ? <Progres color={'green'} value={getProgresbyApprenant(idA, val.id) > 100 ? 100 : getProgresbyApprenant(idA, val.id)} />
                                                            : getAccesbyApprenant(idA, val.id) === "commencer"
                                                                ? <Progres color={'green'} value={getProgresbyApprenant(idA, val.id) > 100 ? 100 : getProgresbyApprenant(idA, val.id)} />
                                                                : null
                                                }
                                                <Heading size='md'>{val.titre}</Heading>
                                                <Text fontSize='xs' style={{ color: "#809fb8" }}>{truncateDescription(val.description)}</Text>
                                            </Stack>
                                        </CardBody>
                                        <CardFooter style={{ justifyContent: "end" }} >
                                            <Stack direction='row' spacing={4} align='center'>
                                                {modLoad ? (
                                                    <Flex
                                                        alignItems="center"
                                                        border="1px"
                                                        borderColor="yellow.500"
                                                        borderRadius="md"
                                                        padding="2"
                                                        backgroundColor="yellow.100"
                                                    >
                                                        <Spinner size="sm" mr={2} color="teal.500" />
                                                        <Text color="yellow.600" fontWeight="bold">Demande en cours...</Text>
                                                    </Flex>
                                                ) : grantedAccess.some(access => access.module === val.id) ? (
                                                    <Button
                                                        colorScheme='teal'
                                                        variant='solid'
                                                        onClick={() => openModContent(val.id, val.titre, location.state.titre, val.description, val.formation, getProgresbyApprenant(idA, val.id))}
                                                    >
                                                        Commencer
                                                    </Button>
                                                ) : ongoingAccess.some(access => access.module === val.id) ? (
                                                    <Flex
                                                        alignItems="center"
                                                        border="1px"
                                                        borderColor="yellow.500"
                                                        borderRadius="md"
                                                        padding="2"
                                                        backgroundColor="yellow.100"
                                                    >
                                                        <Spinner size="sm" mr={2} color="yellow.600" />
                                                        <Text color="yellow.600" fontWeight="bold">Demande en cours...</Text>
                                                    </Flex>
                                                ) : requestActivationOngoing.some(access => access.module === val.id) ? (  // New condition for ongoing reactivation
                                                    <Flex
                                                        alignItems="center"
                                                        border="1px"
                                                        borderColor="blue.500" // Adjust color as needed
                                                        borderRadius="md"
                                                        padding="2"
                                                        backgroundColor="blue.100" // Adjust color as needed
                                                    >
                                                        <Spinner size="sm" mr={2} color="blue.600" /> // Adjust color as needed
                                                        <Text color="blue.600" fontWeight="bold">En cours de réactivation...</Text> {/* New text for ongoing reactivation */}
                                                    </Flex>
                                                ) : reactivationAccess.some(access => access.module === val.id) ? (
                                                    <Flex
                                                        alignItems="center"
                                                        border="1px"
                                                        borderColor="red.500"
                                                        borderRadius="md"
                                                        padding="2"
                                                        backgroundColor="red.100"
                                                        cursor="pointer" // This makes the entire flex clickable
                                                        onClick={() => updateAccessRequest(val.id, idA)} // Use the update method here
                                                    >
                                                        <WarningIcon color="red.500" mr={2} />
                                                        <Text color="red.600" fontWeight="bold">Accès bloqué (réactiver)</Text>
                                                    </Flex>
                                                ) : (
                                                    <Button
                                                        colorScheme='teal'
                                                        variant='outline'
                                                        onClick={() => postAccessRequest(val.id, idA)}
                                                    >
                                                        Demandez l'accès
                                                    </Button>
                                                )}

                                            </Stack>

                                        </CardFooter>
                                    </Card>

                                )
                            })}
                        </SimpleGrid>
                    </Box>
                    <Flex justifyContent="center" alignItems="center" mt={6}>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            isDisabled={currentPage === 1}
                            colorScheme="yellow"
                            variant="outline"
                            mr={2}
                            leftIcon={<span>&lt;</span>} // Left Arrow
                        >
                            Précédent
                        </Button>
                        <Text mx={2} fontSize="lg" fontWeight="bold" color="yellow.500">
                            Page {currentPage} sur {totalPages}
                        </Text>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            isDisabled={currentPage === totalPages}
                            colorScheme="yellow"
                            variant="outline"
                            ml={2}
                            rightIcon={<span>&gt;</span>} // Right Arrow
                        >
                            Suivant
                        </Button>
                    </Flex>
                </Box>
            )}




        </Box>
    )
}

export default Modules






