import { Box, Button, Flex, Icon, Spinner, Text, useToast } from '@chakra-ui/react';


import React, { useEffect, useRef, useState } from 'react';



import { FaArrowCircleLeft, FaArrowLeft, FaCheckCircle } from 'react-icons/fa';


import { pdfjs } from 'react-pdf';
import PSPDFKit from 'pspdfkit';


import { useLocation, useNavigate } from 'react-router-dom';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import axiosClient from '../../../../axios-client';

// import "@cyntler/react-doc-viewer/dist/index.css";

import { useStateContext } from '../../../../context/ContextProvider';



import Lottie from 'react-lottie';


import animationData from '../../../../assets/lot/pdfLoad.json'; // Replace with your actual path

// pdfjs.GlobalWorkerOptions.wor/kerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



export default function SContenuModuleDocs({ idmed, idmod, showButton, goBack }) {


    const toast = useToast()


    const [numPages, setNumPages] = useState(null);


    const [pageNumber, setPageNumber] = useState(1);


    const location = useLocation()


    const [docs, setDocs] = useState([]);


    // const [media, setMedia] = useState([]);


    const [pmc, setProgModContent] = useState([]);


    const [progres, setProgres] = useState([]);


    const [progressId, setProgressId] = useState()


    const { user, setUser } = useStateContext();


    const progress = 100 / location.state.chapcount.length


    // console.log("Vprogress", progress);



    useEffect(() => {


        axiosClient.get('auth/user/')


            .then(({ data }) => {


                setUser(data)


                // console.log()


            })


    }, [])


    const [idA, setIdA] = useState(null)


    useEffect(() => {


        if (user && user.id) {


            setIdA(user.id)


        }


    }, [user])


    function onDocumentLoadSuccess({ numPages }) {


        setNumPages(numPages);


    }


    function goToPreviousPage() {


        if (pageNumber > 1) {


            setPageNumber(pageNumber - 1);


        }


    }


    function goToNextPage() {


        if (pageNumber < numPages) {


            setPageNumber(pageNumber + 1);


        }


    }


    // useEffect(() => {


    //     axiosClient.get('docs/')


    //         .then((res) => {


    //             setDocs(res.data)


    //         })


    // }, [])


    // useEffect(() => {


    //     axiosClient.get('media/')


    //         .then((res) => {


    //             setMedia(res.data)


    //         })


    // }, [])

    useEffect(() => {
        const fetchAllProgressModContent = async () => {
            let allPmc = [];

            // setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogressmod/', { params: { apprenant: user.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                console.error("Error fetching progress mod content:", error);
            }

            // Set the progress mod content and end loading
            setProgModContent(allPmc);
            // setLoading(false); // End loading after fetching
        };

        fetchAllProgressModContent();
    }, [user.id]); // Add user.id as a dependency


    useEffect(() => {
        const fp = async () => {
            let allPmc = [];

            // setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogress/', { params: { apprenant: user.id, module: location.state.idmodule } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                console.error("Error fetching progress  content:", error);
            }

            // Set the progress mod content and end loading
            setProgres(allPmc);
            // setLoading(false); // End loading after fetching
        };

        fp();
    }, [user.id, location.state.idmodule]);


    const mod = location.state.idmodule || idmod



    useEffect(() => {
        if (!user.id || !location.state) return; // Early exit if user.id is not ready

        axiosClient.get(`progres/geProgress/?idModule=${location.state.idmodule}&idApprennant=${user.id}`)
            .then((res) => {
                setProgressId(res.data);
            })
            .catch((error) => {
                console.error("Error fetching progress:", error);
            });
    }, [user.id, location.state]);

    // console.log("usssserIDDDDDD", user.id);



    // const mediaCountLength = count();












    // function count() {


    //     const mediaCount = [];


    //     media.forEach((m) => {


    //         if (m.module === location.state.idchapitre) {



    //             const index = mediaCount.findIndex((item) => item.id === media.id);


    //             if (index >= 0) {


    //                 mediaCount[index].count++;


    //             } else {


    //                 mediaCount.push({ id: m.id, count: 1 });


    //             }




    //         }


    //     });


    //     return mediaCount.length


    // }


    // console.log("user.iduser.id111111", user.id);
    // console.log("all pmc   medix", pmc);


    const [completedMedia, setCompletedMedia] = useState(new Set());


    // console.log("pdmmmdmddmc", progres);


    const checkPassed = (idMedia, idApp) => {
        const handleButtonClick = (val) => {
            // Update the local state immediately
            setCompletedMedia(prev => new Set(prev).add(idMedia));

            axiosClient
                .put(`progressmodcontent/${val.id}/`, {
                    etat: true,
                    media: idMedia,
                    apprenant: user.id,
                })
                .then(() => {
                    const promises = [];
                    const formData = new FormData();

                    // Prepare data for the request
                    formData.append('progres', progress);
                    formData.append('module', location.state.idmodule);
                    formData.append('apprenant', user.id);

                    // // Check if progress exists for the user and module
                    // if (progres && progres.apprenant === user.id && progres.module === location.state.idmodule) {
                    //     // Update existing progress
                    //     progres.progress += progress; // Update the progress value
                    //     const progressFormData = new FormData();
                    //     progressFormData.append('progres', progres.progress);
                    //     promises.push(axiosClient.patch(`/progres/${progres.id}/`, progressFormData)
                    //         .catch(() => {
                    //             // Suppress error logging
                    //         }));
                    // } else {
                    //     // Only make the POST request if there's no existing progress
                    //     promises.push(
                    //         axiosClient.post('progres/', formData)
                    //             .catch(() => {
                    //                 // Suppress error logging
                    //             })
                    //     );
                    // }

                    // Wait for all promises to complete
                    Promise.all(promises)
                        .then(() => {
                            // Handle successful responses if needed
                        })
                        .catch(() => {
                            // Suppress error logging
                        });
                })
                .catch(() => {
                    // Suppress error logging
                });
        };

        return pmc.map((val) => {
            if (val.apprenant === idApp && val.media === idMedia) {
                const isCompleted = completedMedia.has(idMedia) || val.etat;

                if (isCompleted) {
                    return (
                        <Button key={val.id} isDisabled bg={"green"}>
                            <Flex alignItems="center">
                                <Icon color={"white"} as={FaCheckCircle} mr="2" />
                                <Text color={"white"}>Terminé</Text>
                            </Flex>
                        </Button>
                    );
                } else {
                    return (
                        <Button key={val.id} bg={"#FFD24C"} onClick={() => handleButtonClick(val)}>
                            Marquer comme terminé
                        </Button>
                    );
                }
            }
            return null;
        });
    };

    const [doc, setDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    // Retrieve id from location state or a fallback value
    // const idToFetch = location.state?.idm || idmed;
    const idToFetch = idmed;


    // console.log("The Id media", idmed);


    useEffect(() => {
        const fetchDocById = async () => {
            try {
                setLoading(true); // Set loading state to true
                const response = await axiosClient.get(`currentDoc/?id=${idToFetch}`);
                setDoc(response.data); // Set the document data
            } catch (err) {
                setError(err.message); // Set error if there is an issue
            } finally {
                setLoading(false); // Set loading state to false after fetching
            }
        };

        fetchDocById(); // Call the function to fetch document
    }, [idToFetch]);






    const [prf, setPrf] = useState("https://d3ezojp02ml2mb.cloudfront.net/uploads/docs/Cw2ebNqD1Y_1730298014955.pdf");



    // console.log("current doc is the follwoing     : ", doc);

    // // https://d3ezojp02ml2mb.cloudfront.net/uploads/docs/Cw2ebNqD1Y_1730298014955.pdf
    // const containerRef = useRef(null);

    // console.log("th dffofof", doc && doc.file);
    // useEffect(() => {
    //     let instance;

    //     // Initialize PSPDFKit
    //     const loadPdf = async () => {
    //         if (prf) { // Check if doc and doc.file are defined
    //             instance = await PSPDFKit.load({
    //                 container: containerRef.current,
    //                 document: prf,
    //                 // Optional: Other configuration options
    //             });
    //         } else {
    //             console.error("Document file is not available.");
    //         }
    //     };

    //     loadPdf();

    //     // Cleanup on unmount
    //     return () => {
    //         if (instance) {
    //             instance.unload();
    //         }
    //     };
    // }, [prf]);

    const nav = useNavigate()


    if (loading) {
        const defaultOptions = {
            loop: true, // Set to false if you want it to play once
            autoplay: true, // Starts playing the animation automatically
            animationData: animationData, // The imported animation JSON file
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice', // Adjust the aspect ratio as needed
            },
        };

        return (
            <Flex justify="center" align="center" height="100vh">
                <Lottie options={defaultOptions} height={200} width={200} />
            </Flex>
        );
    }

    // Show an error message if there is an error
    if (error) {
        return <div>Error: {error}</div>;
    }


    // console.log("IDDDDDDoc", doc.id);
    // console.log("the irelevent informations", doc.id, user.id);


    return (

        <>
            <Flex justifyContent={'flex-end'}>
                {showButton ? (
                    <Button mt={"5px"} bg={"#089bd7"} px={{ base: '4', md: '6' }} width={"10%"} onClick={() => nav((-1))}>
                        <Flex alignItems="center">
                            <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                            <Text color={'#ffd140'}>Retour</Text>
                        </Flex>
                    </Button>
                ) : (
                    <Text></Text>
                )}
            </Flex>

            <Flex mt="10px" w="100%" h="100%">
                <Flex w="full" direction="column">
                    <Box height="600px">
                        {loading ? ( // Show spinner while loading
                            <Flex justifyContent="center" alignItems="center" height="100%">
                                <Spinner size="xl" />
                            </Flex>
                        ) : (
                            <>
                                {doc.typeDoc === 'fichier' && (
                                    <DocViewer documents={[{
                                        uri: doc.file,
                                    }]} pluginRenderers={DocViewerRenderers} />
                                )}
                                {doc.typeDoc === 'lien' && (
                                    <iframe
                                        src={doc.lienPPT}
                                        title="Link Viewer"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    <Flex justifyContent="end" mr="3" mt="40px">
                        {checkPassed(doc.id, user.id)} {/* Assuming checkPassed is defined */}
                    </Flex>
                </Flex>
            </Flex>
        </>

    )


}
