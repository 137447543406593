import { Box, Button, Flex, Icon, Spacer, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'


import { AiOutlineFilePpt, AiOutlineFileZip } from 'react-icons/ai'
import { MdOndemandVideo } from 'react-icons/md'

import { GrChapterAdd } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosClient from '../../../../axios-client'
import { useStateContext } from '../../../../context/ContextProvider'
import { Submodcontent } from './SContenuModule'
import SContenuModuleDocs from './SContenuModuleDocs'
import { FaArrowCircleLeft, FaArrowCircleRight, FaCheckCircle } from 'react-icons/fa'
import SContenuPpt from './SContenuPpt'

export default function TabbedContent() {

    const { user, setUser } = useStateContext();

    const [chaps, setChaps] = useState([])
    const [meds, setMeds] = useState([])
    const [pmc, setPmc] = useState([])
    const nav = useNavigate()
    const [indexMedia, setIndexMedia] = useState(0)
    const location = useLocation()


    const [currentTabs, setCurrentTabs] = useState({ [location.state.idmodule]: location.state.mediaIndex || 0 });

    // console.log("mod id", location.state.idmodule);
    const allTabPanels = chaps.flatMap((val) => {
        if (val.module === location.state.idmodule && val.id === location.state.idchapitre) {
            const mediaItems = meds
                .filter(media => media.chapitre === val.id
                    && pmc.some(p => p.media === media.id && p.apprenant === user.id)
                    && media.type !== 'Link'
                )
                .map(media => ({


                    ...media,
                    order_index: pmc.find(p => p.media === media.id && p.apprenant === user.id)?.order_index || 0,
                }))
                .sort((a, b) => a.order_index - b.order_index); // Sort by order_index

            return mediaItems.map(media => {
                if (media.type === 'Video') {
                    return (
                        <TabPanel key={media.id}>
                            <Submodcontent showButton={true} i={location.state.idv[media.order_index]} />
                        </TabPanel>
                    );
                } else if (media.type === 'PDF') {
                    return (
                        <TabPanel key={media.id}>
                            <SContenuModuleDocs goBack={false} showButton={true} idmed={media.id} idmod={location.state.idmodule} />
                        </TabPanel>
                    );
                } else if (media.type === 'PPT') {
                    return (
                        <TabPanel key={media.id}>
                            <SContenuPpt goBack={false} showButton={false} idmed={media.id} idmod={location.state.idmodule} />
                        </TabPanel>
                    );
                }
                return null;
            });
        }
        return [];
    });


    const currentTab = location.state.mediaIndex;
    const handleNextButtonClick = () => {
        if (currentTabIndex < allTabPanels.length - 1) {
            setCurrentTabIndex((prevIndex) => prevIndex + 1);
        } else {
            // console.log("You are at the last tab.");
            // Optionally, you could redirect or perform another action here
        }
    };

    const handleGoBackButtonClick = () => {
        if (currentTabIndex > 0) {
            setCurrentTabIndex((prevIndex) => prevIndex - 1);
        } else {
            // console.log("You are at the first tab.");
            // Optionally, handle going back to another screen or module
        }
    };

    // Check if it's the last tab for the current module
    const isLastTab = currentTab === allTabPanels.length - 1;

    useEffect(() => {
        if (isLastTab) {
            // Perform your desired action when in the last tab
            // console.log('Last tab reached!');
        }
    }, [isLastTab]);


    useEffect(() => {
        if (chaps.length === 0) {
            const params = {
                module_id: location.state.idmodule, // Pass the module ID
                apprenant_id: user.id, // Pass the user ID
            };

            axiosClient.get('chapitres-by-module/', { params }) // Use the new endpoint with parameters
                .then((res) => {
                    setChaps(res.data.results);
                })
                .catch((error) => {
                    console.error("Error fetching chapters:", error);
                });
        }
    }, [chaps.length, location.state.idmodule, user.id]);



    // console.log("the all chapitres  ", chaps);


    const [loading, setLoading] = useState(true); // Loading state
    useEffect(() => {
        const fetchAllProgressModContent = async () => {
            let allPmc = [];

            setLoading(true); // Start loading before fetching

            try {
                const res = await axiosClient.get('cprogressmod/', { params: { apprenant: user.id } });
                const data = res.data;

                // Set the complete list of progress mod content
                allPmc = data; // Assuming you want just the results from the first page
            } catch (error) {
                console.error("Error fetching progress mod content:", error);
            }

            // Set the progress mod content and end loading
            setPmc(allPmc);
            setLoading(false); // End loading after fetching
        };

        // Fetch data only if user.id exists
        if (user.id) {
            fetchAllProgressModContent();
        } else {
            setPmc([]); // Clear pmc if user.id doesn't exist
        }
    }, [user.id]); // Add user.id as a dependency

    // console.log("alllll pmc,", pmc);




    useEffect(() => {
        // Check if user and user.id are available
        if (user && user.id && location.state?.idmodule) {
            axiosClient
                .get(`/media_chapitres/?module_id=${location.state.idmodule}&apprenant_id=${user.id}`)
                .then((res) => {
                    // console.log("API Response:", res.data); // Log response data
                    setMeds(res.data); // Set state
                })
                .catch((error) => {
                    console.error('Error fetching media chapters:', error); // Handle errors
                });
        }
    }, [location.state?.idmodule, user]);


    // console.log("medPPOPOPP", meds);


    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])


    // console.log("meds", meds);






    const [isReady, setIsReady] = useState(false);



    // useEffect(() => {
    //     chaps.forEach((val) => {
    //         if (val.module === location.state.idmodule && val.id === location.state.idchapitre) {
    //             let mediaIndex = -1

    //             meds.forEach((media) => {
    //                 pmc.forEach((p) => {
    //                     if (media.id === p.media && p.apprenant === user.id && media.chapitre === val.id) {
    //                         if (media.type === "Video" || media.type === "PDF") {
    //                             mediaIndex++

    //                             if (p.media === location.state.idm) {
    //                                 setIndexMedia(mediaIndex)
    //                                 setIsReady(true)
    //                             }
    //                         }
    //                     }
    //                 })
    //             })
    //         }
    //     })
    // }, [chaps, meds, pmc, location, user])


    // console.log("index meddddddia", indexMedia);



    // if (!isReady) {
    //     return <Flex justifyContent="center" alignItems="center" height="83vh" >
    //         <Spinner size="xl" />
    //     </Flex>
    // }


    function getChapitreName() {
        let chap_name
        chaps.map((c, k) => {
            if (c.id === location.state.idchapitre)
                chap_name = c.name
        })
        return chap_name
    }



    // function mediasCp() {

    //     let medias = []
    //     chaps.map((val, key) => {
    //         if (val.module === location.state.idmodule) {
    //             meds.map((media) => {
    //                 pmc.map((p, k) => {
    //                     if (p.media === media.id && media.chapitre === val.id && val.id === location.state.idchapitre) {

    //                         medias.push(media.name)
    //                     }
    //                 })



    //             })
    //         }
    //     })
    //     return medias

    // }



    // console.log("all the chapters", chaps.length);


    // console.log("idvxc", location.state.idv);
    const getVideoIds = () => {
        const storedVideoIds = localStorage.getItem('videoIds'); // Retrieve the stored data

        if (storedVideoIds) {
            try {
                // Parse the JSON string back to an array
                return JSON.parse(storedVideoIds);
            } catch (error) {
                console.error("Error parsing video IDs from localStorage:", error);
                return []; // Return an empty array if parsing fails
            }
        }

        return []; // Return an empty array if no data found
    };

    const storedVideoIds = localStorage.getItem('videoIds');

    // console.log("storedVideoIds", storedVideoIds)


    // console.log("location.state.idvx", location.state.idv);

    // console.log("location.state.mediaIndex", location.state.mediaIndex);



    // console.log("the fucking current tab is", currentTab);
    // useEffect(() => {
    //     console.log("Current Tabs State:", currentTabs);
    // }, [currentTabs]);

    const [currentTabIndex, setCurrentTabIndex] = useState(location.state.mediaIndex || 0);

    const handleTabChange = (index) => {
        setCurrentTabIndex(index);
    };

    // console.log("the Mecax", media);


    if (loading) {
        return (
            <Flex
                justifyContent="center"
                alignItems="center"
                height="100vh" // Make sure to set the height to fill the viewport
            >
                <Spinner size="xl" />
            </Flex>
        ); // Show loading spinner while fetching
    }


    return (
        <Stack>
            {/* <Text>houhouhouhou</Text> */}


            {/* <Flex justifyContent={'flex-end'}>
                <Button bg={"#089bd7"} px={{ base: '4', md: '6' }} width={"10%"} onClick={() => nav((-1))}>
                    <Flex alignItems="center">
                        <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                        <Text color={'#ffd140'}>Retour</Text>
                    </Flex>
                </Button>
            </Flex> */}


            <Tabs >
                <TabList>
                    {chaps.map((val, ckey) => {
                        if (val.module === location.state.idmodule && val.id === location.state.idchapitre) {
                            return (
                                <Tab key={`tab-${ckey}`} _selected={{ color: "white", bg: "#089bd7" }}>
                                    <Box display="flex" alignItems="center">
                                        <GrChapterAdd size={18} style={{ marginRight: "0.5rem" }} />
                                        Chapitre : {val.name}
                                    </Box>


                                </Tab>

                            )
                        }
                    })}
                </TabList>

                <Box display="flex" justifyContent="flex-end">


                    <Button mt={"5px"} mr={"8px"} bg={'#089bd7'} px={{ base: '2', md: '2' }} onClick={() => nav((-1))}>
                        <Flex alignItems="center">
                            <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                            <Text color={'#ffd140'}>Retour</Text>
                        </Flex>
                    </Button>
                </Box>



                {/* 
                <Box mt="6" px="4" justifyContent="center" display="flex">
                    <Button
                        onClick={handleNextButtonClick}
                        bg="blue.500"
                        color="white"
                        _hover={{ bg: 'blue.600' }}
                        _active={{ bg: 'blue.700' }}
                        disabled={currentTab === allTabPanels.length - 1}
                    >
                        Suivant
                    </Button>
                </Box> */}

                <TabPanels>
                    <TabPanel>
                        {/* {console.log("Index media hh", indexMedia)
                        } */}
                        <Tabs
                            index={currentTabIndex} onChange={handleTabChange}
                        >
                            <TabList
                                as={Box}
                                overflowX="auto"
                                whiteSpace="nowrap"
                                py="2"
                                px="4"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                                sx={{
                                    "&::-webkit-scrollbar": {
                                        height: "6px",
                                        borderRadius: "6px",
                                        backgroundColor: "gray.300",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#089bd7",
                                        borderRadius: "6px",
                                    },
                                }}
                            >
                                {chaps.map((val) => {
                                    if (val.module === location.state.idmodule && val.id === location.state.idchapitre) {
                                        // Filter and map to get media items with order_index
                                        const mediaItems = meds
                                            .filter(media =>
                                                media.chapitre === val.id && media.type !== 'Link' &&
                                                pmc.some(p => p.media === media.id && p.apprenant === user.id)
                                            )
                                            .map(media => ({
                                                ...media,
                                                order_index: pmc.find(p => p.media === media.id && p.apprenant === user.id)?.order_index || 0,
                                            }))
                                            .sort((a, b) => {
                                                // Sort by type first (Video, then PDF), and then by order_index if types are the same
                                                const typeOrder = { Video: 1, PDF: 2 }; // Define order
                                                return (typeOrder[a.type] || 3) - (typeOrder[b.type] || 3) || (a.order_index - b.order_index);
                                            });

                                        // Render the tabs based on sorted media items
                                        return mediaItems.map(media => (
                                            <Tab
                                                key={media.id}
                                                _selected={{ color: "white", bg: media.type === "Video" ? "#089bd7" : media.type === "PP" ? "#e53e3e" : "#3182ce" }}
                                            // onClick={() => console.log("Selected media:", media)} // Log media on click
                                            >
                                                <Box display="flex" alignItems="center">
                                                    {media.type === "Video" ? (
                                                        <MdOndemandVideo size={18} style={{ marginRight: "0.5rem" }} />
                                                    ) : media.type === "PPT" ? (
                                                        <AiOutlineFilePpt size={18} style={{ marginRight: "0.5rem" }} /> // Corrected type check for PPT
                                                    ) : (
                                                        <AiOutlineFileZip size={18} style={{ marginRight: "0.5rem" }} />
                                                    )}
                                                    {media.name}
                                                </Box>
                                            </Tab>
                                        ));
                                    }
                                    return null; // Return null if the conditions are not met
                                })}

                            </TabList>


                            <Box display="flex" justifyContent="flex-end">
                                {/* Check if there's only one tab */}
                                {allTabPanels.length === 1 ? (
                                    // If there's only one tab, show nothing
                                    <></>
                                ) : (
                                    <>
                                        {/* Show "Suivant" button only on the first tab */}
                                        {currentTabIndex === 0 && (
                                            <Button mt={"5px"} bg={'#089bd7'} px={{ base: '2', md: '2' }} onClick={handleNextButtonClick}>
                                                <Flex alignItems="center">
                                                    <Text color={'#ffd140'}>Suivant</Text>
                                                    <p>&nbsp;&nbsp;&nbsp;</p>
                                                    <Icon color={'#ffd140'} as={FaArrowCircleRight} mr="2" />
                                                </Flex>
                                            </Button>
                                        )}

                                        {/* Show both "Précédent" and "Suivant" buttons when in the middle tabs */}
                                        {currentTabIndex > 0 && currentTabIndex < allTabPanels.length - 1 && (
                                            <>
                                                <Button mt={"5px"} mr={"8px"} bg={'#089bd7'} px={{ base: '2', md: '2' }} onClick={handleGoBackButtonClick}>
                                                    <Flex alignItems="center">
                                                        <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                                                        <Text color={'#ffd140'}>Précédent</Text>
                                                    </Flex>
                                                </Button>
                                                <Button mt={"5px"} bg={'#089bd7'} px={{ base: '2', md: '2' }} onClick={handleNextButtonClick}>
                                                    <Flex alignItems="center">
                                                        <Text color={'#ffd140'}>Suivant</Text>
                                                        <p>&nbsp;&nbsp;&nbsp;</p>
                                                        <Icon color={'#ffd140'} as={FaArrowCircleRight} mr="2" />
                                                    </Flex>
                                                </Button>
                                            </>
                                        )}

                                        {/* Show "Précédent" button only on the last tab */}
                                        {currentTabIndex === allTabPanels.length - 1 && (
                                            <Button mt={"5px"} bg={'#089bd7'} px={{ base: '2', md: '2' }} onClick={handleGoBackButtonClick}>
                                                <Flex alignItems="center">
                                                    <Icon color={'#ffd140'} as={FaArrowCircleLeft} mr="2" />
                                                    <Text color={'#ffd140'}>Précédent</Text>
                                                </Flex>
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>





                            <TabPanels>
                                {chaps.map((val) => {
                                    // console.log("location.state.idvlocation.state.idv", location.state.idv);

                                    if (val.module === location.state.idmodule && val.id === location.state.idchapitre) {
                                        const mediaItems = meds
                                            .filter(media => media.chapitre === val.id && pmc.some(p => p.media === media.id && p.apprenant === user.id))
                                            .map(media => ({
                                                ...media,
                                                // order_index: pmc.find(p => p.media === media.id && p.apprenant === user.id)?.order_index || 0,
                                            }))
                                            .sort((a, b) => {
                                                // Sort by type first (Video, then PDF, then PPT), then by order_index if types are the same
                                                const typeOrder = { Video: 1, PDF: 2, PPT: 3 }; // Define order for PPT
                                                return (typeOrder[a.type] || 4) - (typeOrder[b.type] || 4) || (a.order_index - b.order_index);
                                            });
                                        // console.log("mediaItemsmediaItems", mediaItems);

                                        // Separate arrays for video, PDF, and PPT tabs
                                        const videoTabs = mediaItems.filter(media => media.type === "Video").map(media => {
                                            // console.log("Video Media Item:", media); // Log the media item
                                            return (
                                                <TabPanel key={media.id}>
                                                    <Submodcontent showButton={true} i={location.state.idv[media.order_index]} />
                                                </TabPanel>
                                            );
                                        });

                                        const docTabs = mediaItems.filter(media => media.type === "PDF").map(media => (
                                            <TabPanel key={media.id}>
                                                <SContenuModuleDocs goBack={false} showButton={false} idmed={media.id} idmod={location.state.idmodule} />
                                            </TabPanel>
                                        ));

                                        const pptTabs = mediaItems.filter(media => media.type === "PPT").map(media => (
                                            <TabPanel key={media.id}>
                                                <SContenuPpt goBack={false} showButton={false} idmed={media.id} idmod={location.state.idmodule} />
                                            </TabPanel>
                                        ));

                                        // Return combined tabs ensuring they maintain their respective order
                                        return [...videoTabs, ...docTabs, ...pptTabs];
                                    }
                                    return null; // Ensure that a return value is provided if conditions fail
                                })}
                            </TabPanels>


                        </Tabs>


                    </TabPanel>

                </TabPanels>
            </Tabs>


        </Stack>
    )
}